import React from 'react';
import TextField from '@material-ui/core/TextField';
import {withStyles} from '@material-ui/core/styles';
import {Card, Grid} from '@material-ui/core';
import {GoogleReCaptcha, GoogleReCaptchaProvider} from "react-google-recaptcha-v3";

const styles = (theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
});

const recaptchaRef = React.createRef();

class AddUser extends React.Component {
    state = {
        user: {},
        isEnabled: false,
    };

    // Method to update the values of the text fields
    handleChange = e => {
        this.props.updateData(e.target.name, e.target.value);
    };

    goBack = () => {
        this.props.history.goBack();
    };

    // Method to update the recaptcha value in the parent component
    onChange = (value) => {
        this.props.updateRecaptcha(value);
    };

    render() {
        const {classes} = this.props;
        let user = {...this.props.user};

        return (
            <div>
                <Card>
                    <div style={{textAlign: 'center'}}>
                        <form className={classes.root} autoComplete="on">
                            <h5>Enter User Information</h5>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={12}>
                                    <TextField
                                        required
                                        id="first_name"
                                        name="first_name"
                                        label="First Name"
                                        variant="outlined"
                                        value={user.first_name}
                                        onChange={e => this.handleChange(e)}
                                    />
                                    <TextField
                                        id="middle_names"
                                        name="middle_names"
                                        label="Middle Name"
                                        variant="outlined"
                                        value={user.middle_names}
                                        onChange={e => this.handleChange(e)}
                                    />
                                    <TextField
                                        required
                                        id="last_name"
                                        name="last_name"
                                        label="Last Name"
                                        variant="outlined"
                                        value={user.last_name}
                                        onChange={e => this.handleChange(e)}
                                    />
                                    <TextField
                                        required
                                        id="email"
                                        name="email"
                                        label="Email"
                                        variant="outlined"
                                        type="email"
                                        value={user.email}
                                        onChange={e => this.handleChange(e)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <TextField
                                        required
                                        id="password"
                                        name="password"
                                        label="Password"
                                        variant="outlined"
                                        type="password"
                                        value={user.password}
                                        onChange={e => this.handleChange(e)}
                                    />
                                    <TextField
                                        required
                                        id="password_confirmation"
                                        name="password_confirmation"
                                        label="Password Confirmation"
                                        variant="outlined"
                                        type="password"
                                        value={user.password_confirmation}
                                        onChange={e => this.handleChange(e)}
                                    />

                                </Grid>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <GoogleReCaptchaProvider reCaptchaKey="6Ldj9VgaAAAAAAZykan5R_cXJaukHDuT-FNL7Nbs">
                                            <GoogleReCaptcha
                                                id="recaptchaValue"
                                                name='recaptchaValue'
                                                ref={recaptchaRef}
                                                onVerify={this.onChange} />
                                        </GoogleReCaptchaProvider>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </Card>
            </div>
        );
    }
}

export default withStyles(styles)(AddUser);


