import React from 'react';
import Table from "material-table";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Utils from "../../utils/utils";
import * as cookies from "react-cookies";
import {Api} from "../../config/api_config";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from '@material-ui/core/DialogActions';
import Button from "@material-ui/core/Button";
import TextField from '@material-ui/core/TextField';
import MenuItem from "@material-ui/core/MenuItem";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import LcService from "../../services/lcService";


class MainReport extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            studentReports: [
                {name: "Admission Report by Learning Center", report: 'gemis_admission_report_by_lc', tag: 'onlyLc'},
                {
                    name: "Admission Report by Programme",
                    report: 'gemis_admission_report_by_progam',
                    tag: 'program_name'
                },
                {
                    name: "Admission Report by Learning Center And Programme",
                    report: 'gemis_admission_by_lc_prog',
                    tag: 'LcProg'
                },
                {
                    name: "Enrollment Report by Learning Center",
                    report: 'gemis_enrollment_report_by_lcenter',
                    tag: 'onlyLc'
                },
                {
                    name: "Enrollment Report by Programme",
                    report: 'gemis_enrollment_report_by_program',
                    tag: 'program_name'
                },
                {
                    name: "Enrollment Report by Learning Center And Programme",
                    report: 'gemis_enrollment_report_lc_prog',
                    tag: 'LcProg'
                },
                {name: "Graduation Report by Learning Center", report: 'gemis_graduation_report_by_lc', tag: 'onlyLc'},
                {
                    name: "Graduation Report by Programme",
                    report: 'gemis_graduation_report_by_program',
                    tag: 'program_name'
                },
                {
                    name: "Graduation Report by Learning Center And Programme",
                    report: 'gemis_graduation_report_lc_prog',
                    tag: 'LcProg'
                },
                {
                    name: "Scholarship Report by Learning Center",
                    report: 'gemis_scholarship_by_lc',
                    tag: 'onlyLcScholarship'
                },
                {
                    name: "Scholarship Report by Learning Center And Scholarship Name",
                    report: 'gemis_scholarship_report_lc_schol_name',
                    tag: 'LcScholarship'
                },
                {name: "All Scholarships Report", report: 'gemis_general_scholarship_report', tag: 'date'}
            ],
            showModal: false,
            forGlobal: false,
            reportData: {},
            dialogTrue: false,
            yearOptions: [],
            yearOpen: false,
            courseOptions: [],
            courseOpen: false,
            displayDialog: false,
            learning_center_id: '',
            lcs_higher: [],
            lcs_tertiary: [],
            lcs_non_tertiary: [],
            classification: "Higher Education",
            reportVal: null,
            progName: false,
            LcProg: false,
            onlyLc: false,
            LcScholarship: false,
            programNames: [],
            scholarshipNames: [],
            years: [],
            scholarship_name: '',
            start_year: '',
            end_year: '',
            program_name: '',
            start_end_year: false,
            showRadioButtons: false
        }
    };

    getAdditionalReport = (data, params) => {
        localStorage.setItem('showReport', 'true');
        let dateParams = null;

        let inject = {images: [{name: 'image', attr: 'image', id: params.lc_id, model: 'LearningCenter'}]};
        // this.showReport(data.name, Api.DEV_BASE_URL + '/v1/reports/1?url=/&profile=' + cookies.load('profile_id'),
        this.showReport(data.name, Api.DEV_BASE_URL + '/v1/reports/1?url=/&profile=' + cookies.load('profile_id'),
            {params: params, sub: '&report=' + data.report + '&dynamic=true', inject: inject}, dateParams)

    };

    showReport = (name, baseUrl, parts, params) => {
        Utils.showReportUtility(name, baseUrl, parts, cookies, params);
    };

    displayReport = (event, data) => {
        let _progName = false;
        let _onlyLc = false;
        let _LcProg = false;
        let _start_end_year = false;
        let _LcScholarship = false;
        let _showRadioButtons = false;
        if (data.tag === 'program_name') {
            this.loadProgramNames();
            _progName = true;
        }
        if (data.tag === 'onlyLc') {
            this.loadData();
            _onlyLc = true;
            _showRadioButtons = true;
        }
        if (data.tag === 'LcProg') {
            this.loadData();
            this.loadProgramNames();
            _LcProg = true;
            _showRadioButtons = true;
        }
        if (data.tag === 'LcScholarship') {
            this.loadScholarshipNames();
            this.loadYears();
            this.loadData();
            _onlyLc = true;
            _start_end_year = true;
            _LcScholarship = true;
            _showRadioButtons = true;
        }
        if (data.tag === 'onlyLcScholarship') {
            this.loadYears();
            this.loadData();
            _onlyLc = true;
            _start_end_year = true;
            _showRadioButtons = true;
        }
        if (data.tag === 'date') {
            this.loadYears();
            _start_end_year = true;
        }
        this.setState({
            displayDialog: true,
            reportVal: data,
            progName: _progName,
            onlyLc: _onlyLc,
            LcProg: _LcProg,
            LcScholarship: _LcScholarship,
            start_end_year: _start_end_year,
            showRadioButtons: _showRadioButtons,
        })
    };

    getReport = () => {
        this.closeDialog();
        let params = {
            lc_id: this.state.learning_center_id,
            nat_program_name: this.state.program_name,
            scholarship_name: this.state.scholarship_name,
            start_year: this.state.start_year,
            end_year: this.state.end_year,
        };
        this.getAdditionalReport(this.state.reportVal, params);
    };

    goBack = () => {
        this.props.history.goBack();
    };

    handleChange = (e) => {
        this.setState({[e.target.name]: e.target.value})
    };

    closeDialog = () => {
        this.setState({
            displayDialog: false,
            progName: false,
            LcProg: false,
            onlyLc: false,
            LcScholarship: false,
            start_end_year: false,
        });
        this.clearValues();
    };

    clearValues = () => {
        this.setState({learning_center_id: 1, classification: "Higher Education"})
    };

    getLcData = () => {
        if (this.state.classification === "Higher Education") {
            return this.state.lcs_higher;
        } else if (this.state.classification === "Tertiary Education") {
            return this.state.lcs_tertiary;
        } else {
            return this.state.lcs_non_tertiary;
        }
    };

    loadData = () => {
        LcService.loadSchools('Higher Education', response => {
            this.updateTables('lcs_higher', response.data);
        });

        LcService.loadSchools('Tertiary', response => {
            this.updateTables('lcs_tertiary', response.data);
        });

        LcService.loadSchools('Non Tertiary', response => {
            this.updateTables('lcs_non_tertiary', response.data);
        });
    };

    loadProgramNames = () => {
        LcService.getProgramNames(response => {
            this.updateFields("programNames", response.national_programs);
        });
    };

    loadYears = () => {
        LcService.getYears(response => {
            this.updateFields("years", response.years);
        });
    };

    loadScholarshipNames = () => {
        LcService.getScholarshipNames(response => {
            this.updateFields("scholarshipNames", response.scholar_names);
        });
    };

    updateTables = (name, data) => {
        let temp = [];
        data.forEach(lc => temp.push({label: lc.name, value: lc.id}));
        this.setState({[name]: temp});
    };

    updateFields = (name, data) => {
        let temp = [];
        data.forEach(prog => temp.push({label: prog, value: prog}));
        this.setState({[name]: temp});
    };

    render() {
        return (
            <div>
                <Grid container>
                    <Grid item xs={12} sm={12} md={8}>
                        <Card>
                            <div style={{textAlign: 'center', padding: '1px'}}>
                                <h4>Reports</h4>
                            </div>
                            <div>
                                <Table
                                    columns={[
                                        {
                                            title: '',
                                            field: 'name',
                                            cellStyle: {padding: '4px'},
                                            headerStyle: {padding: '4px'}
                                        },
                                        {title: '', field: 'report', hidden: true},
                                    ]}
                                    data={this.state.studentReports}
                                    onRowClick={this.displayReport}
                                    options={{
                                        columnsButton: false,
                                        sorting: false,
                                        showTitle: false,
                                        toolbar: false,
                                        search: false,
                                        paging: false,
                                    }}
                                />
                            </div>
                        </Card>
                    </Grid>
                </Grid>
                {(this.state.displayDialog) ?
                    <Dialog open={this.state.displayDialog} onClose={this.closeDialog}
                            aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">Select Report Filters</DialogTitle>
                        <DialogContent>
                            <Grid container>
                                {
                                    this.state.showRadioButtons ?
                                        <Grid item xs={12} sm={12} md={12}>
                                            <RadioGroup aria-label="position" name="position" value={this.state.classification}
                                                        onChange={event => this.setState({classification: event.target.value})}
                                                        row>
                                                <FormControlLabel
                                                    checked={this.state.classification === 'Higher Education'}
                                                    label="Higher Education"
                                                    value="Higher Education"
                                                    control={<Radio color="primary"/>}
                                                    name="radio-button-card"
                                                />
                                                <FormControlLabel
                                                    checked={this.state.classification === "Tertiary Education"}
                                                    label="Tertiary Education"
                                                    value="Tertiary Education"
                                                    control={<Radio color="primary"/>}
                                                    name="radio-button-card"
                                                />
                                                <FormControlLabel
                                                    checked={this.state.classification === "Non Tertiary"}
                                                    label="Non Tertiary"
                                                    value="Non Tertiary"
                                                    control={<Radio color="primary"/>}
                                                    name="radio-button-mobile"
                                                />
                                            </RadioGroup>
                                        </Grid> : null
                                }
                                {
                                    this.state.onlyLc || this.state.LcProg ?
                                        <Grid item xs={12} sm={12} md={12} style={{marginBottom: 5}}>
                                            <TextField
                                                required
                                                id="learning_center_id"
                                                name="learning_center_id"
                                                select
                                                label="Learning Center"
                                                value={this.state.learning_center_id}
                                                onChange={this.handleChange}
                                                variant="outlined"
                                                style={{
                                                    border: 0,
                                                    outline: 0
                                                }}
                                                fullWidth
                                            >
                                                {this.getLcData().map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid> : null
                                }
                                {
                                    this.state.progName || this.state.LcProg ?
                                        <Grid item xs={12} sm={12} md={12}>
                                            <TextField
                                                required
                                                id="program_name"
                                                name="program_name"
                                                select
                                                label="Programme Name"
                                                value={this.state.program_name}
                                                onChange={this.handleChange}
                                                variant="outlined"
                                                style={{
                                                    border: 0,
                                                    outline: 0
                                                }}
                                                fullWidth
                                            >
                                                {this.state.programNames.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid> : null
                                }
                                {
                                    this.state.LcScholarship ?
                                        <Grid item xs={12} sm={12} md={12}>
                                            <TextField
                                                required
                                                id="scholarship_name"
                                                name="scholarship_name"
                                                select
                                                label="Scholarship Name"
                                                value={this.state.scholarship_name}
                                                onChange={this.handleChange}
                                                variant="outlined"
                                                style={{
                                                    border: 0,
                                                    outline: 0
                                                }}
                                                fullWidth
                                            >
                                                {this.state.scholarshipNames.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid> : null
                                }
                                {
                                    this.state.start_end_year ?
                                        <>
                                            <Grid item xs={12} sm={12} md={12} style={{marginBottom: 5}}>
                                                <TextField
                                                    required
                                                    id="start_year"
                                                    name="start_year"
                                                    select
                                                    label="Start Year"
                                                    value={this.state.start_year}
                                                    onChange={this.handleChange}
                                                    variant="outlined"
                                                    style={{
                                                        border: 0,
                                                        outline: 0
                                                    }}
                                                    fullWidth
                                                >
                                                    {this.state.years.map((option) => (
                                                        <MenuItem key={option.value} value={option.value}>
                                                            {option.label}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12}>
                                                <TextField
                                                    required
                                                    select
                                                    id="end_year"
                                                    name="end_year"
                                                    label="End Year"
                                                    value={this.state.end_year}
                                                    onChange={this.handleChange}
                                                    variant="outlined"
                                                    style={{
                                                        border: 0,
                                                        outline: 0
                                                    }}
                                                    fullWidth
                                                >
                                                    {this.state.years.map((option) => (
                                                        <MenuItem key={option.value} value={option.value}>
                                                            {option.label}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </Grid>
                                        </>
                                        : null
                                }
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button color="secondary" onClick={() => this.closeDialog()} variant="contained">
                                Cancel
                            </Button>
                            <Button onClick={this.getReport} color="primary" variant="contained">
                                Load
                            </Button>
                        </DialogActions>
                    </Dialog>
                    : null
                }

            </div>
        );
    }
}

export default MainReport;
