import React, {useEffect} from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import analystRoutes from '../../routes/analyst';
import superAdminRoutes from '../../routes/super_admin';
import useStyles from './appLayoutStyles';
import Container from "@material-ui/core/Container";
import {Route, withRouter} from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import LinearProgress from "@material-ui/core/LinearProgress/LinearProgress";
import {connect} from "react-redux";
import AddIndustry from "../Industry/AddIndustry";
import * as cookies from "react-cookies";
import AddInstitution from "../Institutions/AddInstitution";
import InstitutionDetail from "../Institutions/InstitutionDetail";
import Students from "../Institutions/Students/Students";
import AddUser from "../Users/AddUser";
import IndustryDetails from "../Industry/IndustryDetails";
import UserDetails from "../Users/UserDetails";
import StudentsDetails from "../Institutions/Students/StudentsDetails";
import Staff from "../Institutions/Staff/Staff";
import StaffDetails from "../Institutions/Staff/StaffDetails";
import Programs from "../Institutions/Programs/Programs";
import HomeProfile from "../ViewProfile/Home";
import industryRoutes from "../../routes/industry";
import VacancyDetails from "../Vacancy/VacancyDetails";
import AddVacancy from "../Vacancy/AddVacancy";
import partnerRoutes from "../../routes/partner";
import Institution_Industry_Details from "../Industry/Institution_Industry_Details";
import Industry_Program_Details from "../Industry/Industry_Program_Details";
import ReportView from "../Reports/ReportView";
import AddSchAdmin from "../Users/AddSchAdmin";
import schoolAdminRoutes from "../../routes/school_admin";

const BorderLinearProgress = withStyles((theme) => ({
    root: {
        height: 10,
        borderRadius: 5,
    },
    colorPrimary: {
        backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
        borderRadius: 5,
        backgroundColor: '#1a90ff',
    },
}))(LinearProgress);

const AppLayout = (props) => {
    const classes = useStyles();

    const [open, setOpen] = React.useState(true);
    const [user_type, setUserType] = React.useState('');

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        let temp = '';
        if (props.history.location.user) {
            temp = props.history.location.user.user_type;
        }
        if (props.history.location.user === undefined) {
            temp = cookies.load('user_type');
        }

        setUserType(temp);
    }, []);

    useEffect(() => {
        if (cookies.load('user_type') === undefined || cookies.load('token') === undefined) {
            props.history.push('/login');
        }
    }, []);

    const determineRoute = (user_type) => {
        if (user_type === "analyst") return analystRoutes;
        else if (user_type === "industry") return industryRoutes;
        else if (user_type === "partner") return partnerRoutes;
        else if (user_type === "admin") return superAdminRoutes;
        else if (user_type === "school-admin") return schoolAdminRoutes;
    };


    return user_type !== '' && (
        <div className={classes.root}>
            <CssBaseline/>
            {/*Header*/}
            <Header handleDrawerOpen={handleDrawerOpen} open={open}/>

            {/*Sidebar*/}
            <Sidebar handleDrawerClose={handleDrawerClose} open={open} routes={determineRoute(user_type)}/>

            {/*Main contents*/}
            <main className={classes.content}>
                <div className={classes.appBarSpacer}/>
                <Container maxWidth="lg" className={classes.container}>
                    {props.app !== undefined && props.app.isFectching ?
                        <BorderLinearProgress variant="indeterminate"/> : <p>{props.app.isFetching}</p>}


                    {
                        // routes.map((route, key) =>{
                        determineRoute(user_type).map((route, key) => {
                            return <Route exact path={route.path} component={route.component} key={key}/>;
                        })
                    }
                    <Route exact path="/new-industry" component={AddIndustry}/>
                    <Route exact path="/industry/:id/edit" render={(props) => <AddIndustry {...props} />}/>
                    <Route exact path="/industry/:id" component={IndustryDetails}/>

                    {/*Industry schools view routes*/}
                    <Route exact path="/schools/:id" component={Institution_Industry_Details}/>
                    <Route exact path="/schools/:id/programs" component={Programs}/>
                    <Route exact path="/schools/:id/programs/:prog_id" component={Industry_Program_Details}/>

                    {/*Institution Routes*/}
                    <Route exact path="/new-institution" component={AddInstitution}/>
                    <Route exact path="/institutions/:id" component={InstitutionDetail}/>
                    <Route exact path="/institutions/:id/edit" component={AddInstitution}/>
                    <Route exact path="/institutions/:id/students" component={Students}/>
                    <Route exact path="/institutions/:schoolId/students/:id" component={StudentsDetails}/>
                    <Route exact path="/institutions/:id/staff" component={Staff}/>
                    <Route exact path="/institutions/:schoolId/staff/:staffId" component={StaffDetails}/>
                    <Route exact path="/institutions/:id/programs" component={Programs}/>
                    <Route exact path="/institutions/:id/programs/:prog_id" component={Industry_Program_Details}/>

                    {/*User Routes*/}
                    <Route exact path="/new-user" component={AddUser}/>
                    <Route exact path="/school-admin" component={AddSchAdmin}/>
                    <Route exact path="/users/:id" component={UserDetails}/>
                    <Route exact path="/users/:id/edit" component={AddUser}/>

                    <Route exact path="/profile" component={HomeProfile}/>

                    {/*Routes for vacancy*/}
                    <Route exact path="/new-vacancy" component={AddVacancy}/>
                    <Route exact path="/vacancy/:vacancy_id" component={VacancyDetails}/>
                    <Route exact path="/vacancy/:vacancy_id/edit-vacancy" component={AddVacancy}/>
                </Container>
            </main>

            <ReportView/>
        </div>
    );

};

const mapStateToProps = state => {
    const {app} = state;
    return {app};
};

export default connect(mapStateToProps)(withRouter(AppLayout));
