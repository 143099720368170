import React from 'react';
import TextField from '@material-ui/core/TextField';
import {withStyles} from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import {Grid} from '@material-ui/core';
import Utils from "../../utils/utils";
import * as cookies from "react-cookies";
import UserService from "../../services/userService";

const styles = (theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
});

class ViewProfile extends React.Component {
    state = {
        // currentUser: {
        //     first_name: '',
        //     middle_names: '',
        //     last_name: '',
        //     email: ''
        // }
        currentUser: null
    };

    // Method to update values of the text fields
    handleChange = e => {
        let val = e.target.value;
        let _currentUser = {...this.state.currentUser};
        if (e.target.name === 'phone_number' && Utils.isValid(val)) {
            Utils.displayMessage("warning", "", "Only numbers allowed").then(r => r);
            return;
        }
        _currentUser[e.target.name] = val;
        this.setState({
            currentUser: _currentUser
        });
    };

    // Method to navigate back to the previous component/view
    goBack = () => {
        this.props.history.goBack();
    };

    // Method sending request to update details of a user
    updateData = () => {
        UserService.create(this.state.currentUser, response => {
            if (response.data) {
                Utils.displayMessage('success', 'Successful', 'Info successfully updated').then(r => r);
                this.loadUser();
            }
        });
    };

    // Method to load details of the current logged in user
    loadUser = () => {
        UserService.getUser(cookies.load('currentUserId'), data => {
            this.setState({
                currentUser: {...data}
            })
        });
    };

    componentDidMount() {
        this.loadUser();
    };

    render() {
        const {classes} = this.props;
        let current_user = {...this.state.currentUser};
        return (
            <div>
                <div style={{marginLeft: 150}}>
                    <form className={classes.root} autoComplete="on">
                        <h4>Update Details</h4>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={12}>
                                <TextField
                                    required
                                    id="first_name"
                                    name="first_name"
                                    label="First Name"
                                    variant="outlined"
                                    value={current_user.first_name}
                                    onChange={e => this.handleChange(e)}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                <TextField
                                    id="middle_names"
                                    name="middle_names"
                                    label="Middle Name"
                                    variant="outlined"
                                    value={current_user.middle_names}
                                    onChange={e => this.handleChange(e)}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                <TextField
                                    required
                                    id="last_name"
                                    name="last_name"
                                    label="Last Name"
                                    variant="outlined"
                                    value={current_user.last_name}
                                    onChange={e => this.handleChange(e)}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                <TextField
                                    required
                                    id="email"
                                    name="email"
                                    label="Email"
                                    variant="outlined"
                                    value={current_user.email}
                                    onChange={e => this.handleChange(e)}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </form>
                </div>
                <div style={{padding: 5}}>
                    <Button variant="contained" color="primary" onClick={this.updateData} style={{margin: 5}}>
                        Update
                    </Button>
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(ViewProfile);
