import React from "react";
import PropTypes from "prop-types";
import {withStyles} from '@material-ui/core/styles';
import jwt from 'jsonwebtoken';

const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    cardCategory:{
        color: "#000",
        margin: "0px",
        fontSize: "14px",
        fontWeight: "bolder",
        paddingTop: "4px",
    }
});
class StakeHolderDashboard extends React.Component {
    state = this.props.state || {
        iframeUrl: null,
        dashboardId: ''
    };

    constructor(props) {
        super(props);

        this.divRef = React.createRef();
        this.initDashboard();
    }

    initDashboard = () => {
        var METABASE_SITE_URL = "https://analytics.moherstportal.gm";
        var METABASE_SECRET_KEY = "830a50bab48bc900a8ee1572697e8c14e9b8bf7c111141cb673708f7590c5f45";
        var payload = {
            resource: { dashboard: 7},
            params: {}
        };
        var token = jwt.sign(payload, METABASE_SECRET_KEY);
        this.state.iframeUrl = METABASE_SITE_URL + "/embed/dashboard/" + token + "#bordered=true&titled=false";
    };

    getFrameHeight = () => {
        return (this.divRef.current != null) ?
            this.divRef.current.parentNode.parentNode.offsetHeight : 800;
    };

    getProps = () => {
        return this.props.returningProps || this.props;
    };

    render() {
        const {classes} = this.getProps();
        return (
            <div className={classes.root} ref={this.divRef} style={{height:this.getFrameHeight()}}>
                <iframe
                    id={"dashFrame"}
                    onLoad={()=> {
                    }}
                    src={this.state.iframeUrl}
                    frameBorder="0"
                    width="100%"
                    height="100%"
                    allowTransparency
                >
                </iframe>
            </div>
        );
    }
}
StakeHolderDashboard.propTypes = {
    classes: PropTypes.object.isRequired
};
export default withStyles(styles)(StakeHolderDashboard);
