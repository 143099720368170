import React,{Component} from 'react';
import SimpleView from "../SimpleView/SimpleView";
import UserService from "../../services/userService";
import ProgressBar from "../ProgressBar/ProgressBar";

class Users extends Component{
    constructor(props){
        super(props);

        this.state = {
            data: [],
            displayLoader: false
        }
    };

    // Method to load all users
    loadUsers = () => {
        UserService.getUsers(response => {
            if(response.data){
                this.setState({
                    data: response.data,
                    displayLoader: false
                })
            }
        });
    };

    componentDidMount() {
        this.setState({
            displayLoader: true
        });
        this.loadUsers();
        localStorage.clear();
    }

    render(){

        const data = {
            name: 'Users',
            addButton: 'New User',
            addSchAdminButton: 'School Admin',
            buttonPath: '/new-user',
            buttonPathSchAdmin: '/school-admin',
            headers: [
                {title: 'First Name', field: 'first_name'},
                {title: 'Middle Name', field: 'middle_names'},
                {title: 'Last Name', field: 'last_name'},
                {title: 'Email', field: 'email'},
                {title: 'Organization', field: 'org'},
                {title: 'Role', field: 'role'}
            ],
            data: this.state.data
        };
        return (
            <>
                {
                    this.state.displayLoader ? <ProgressBar/> : null
                }
                <div style={{marginTop: 10}}>
                    <SimpleView data={data} showAddButton={true} showAddSchAdminButton={true} />
                </div>
            </>
        );
    }
}

export default Users;

