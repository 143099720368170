import swal from "sweetalert";
import $ from "jquery";
import * as cookies from "react-cookies";

export default class Utils{
    static genders = [
        {label: "Female", value:"Female"},
        {label: "Male", value:"Male"}
    ];

    static GlobalState = null;

    static ownership = [
        {label: "Public", value:"public"},
        {label: "Private", value:"private"}
    ];

    static classification = [
        {label: "Higher Education", value:"higher"},
        {label: "Tertiary", value:"tertiary"},
        {label: "Non Tertiary", value:"non-tertiary"}
    ];

    static financial_source = [
        {label: "Public", value:"public"},
        {label: "Private", value:"private"}
    ];

    static lga = [
        {label: "Banjul", value:"Banjul"},
        {label: "KMC", value:"KMC"},
        {label: "WCR", value:"WCR"},
        {label: "LRR", value:"LRR"},
        {label: "NBR", value:"NBR"},
        {label: "CRR", value:"CRR"},
        {label: "URR", value:"URR"}
    ];

    static sector = [
        {label: "Public", value:"public"},
        {label: "Private", value:"private"}
    ];

    static vacancyJobTypes = [
        {label: "Permanent", value:"Permanent"},
        {label: "Temporal", value:"Temporal"},
        {label: "Internship", value:"Internship"},
    ];

    static qualifications = [
        {label: "Masters", value: "Masters"},
        {label: "Doctorate", value: "Doctorate"},
        {label: "Bachelors", value: "Bachelors"},
        {label: "Diploma Lecturer", value: "Diploma Lecturer"},
        {label: "Ordinary Diploma", value: "Ordinary Diploma"},
        {label: "Higher Diploma", value: "Higher Diploma"},
        {label: "Certificate", value: "WASCertificateSCE"},
        {label: "WASSCE", value: "WASSCE"},
    ];

    static orgTypes = [
        {label: "Industry", value:"Industry"},
        {label: "Ministry/Stakeholder", value:"Stakeholder"},
        {label: "Partner", value:"Partner"},
    ];

    static fieldsOfEducation = [
        {label: "Business Administration", value:"Business Administration"},
        {label: "Economics", value:"Economics"},
        {label: "Financial Accounting", value:"Financial Accounting"},
        {label: "Linguistic", value:"Linguistic"},
        {label: "Public Health", value:"Public Health"},
        {label: "Biology", value:"Bilogy"},
        {label: "Chemistry", value:"Chemistry"},
        {label: "Education", value:"Education"},
        {label: "Telecommunications/Marketing", value:"Telecommunications/Marketing"},
        {label: "Medicine", value:"Medicine"},
        {label: "Pharmacy", value:"Pharmacy"},
        {label: "Law", value:"Law"},
        {label: "Nursing", value:"Nursing"},
        {label: "Physics", value:"Physics"},
        {label: "Computer Science", value:"Computer Science"},
    ];

    static validateEmail(email){
        if(email === null || email === undefined || email.lenth === 0)
            return false;

        var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    static validatePhoneNumber(input){
        var phoneNumber = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s]*[0-9]*$/;
        if(input.match(phoneNumber)) {
            return true;
        }
        else {
            return false;
        }
    };

    static validateName(text) {
        let reg = /^[A-zÀ-ú\s.]+(?:['-][A-zÀ-ú\s.]+)*$/;
        return reg.test(text.trim());
    };

    static validateText(text) {
        let reg =  /^(?!\s)(?!.*\s$)(?=.*[a-zA-Z0-9])[a-zA-Z0-9 '~?!\-]+$/;
        return reg.test(text.trim());
    };

    static displayMessage = (icon, title, text) => {
        return swal({
            title: title,
            text: text,
            icon: icon,
            timer: 3000,
            buttons: false
        });
    };

    static isValid = (val) =>{
        return (isNaN(+(val)));
    };

    static formatDate = (date) => {
        let _date = date.split('-');
        let temp = _date[0];
        _date[0] = _date[2];
        _date[2] = temp;
        return _date.join('-');
    };

    static getToday = () => {

        let date = new Date();
        let dd = date.getDate();
        let mm = date.getMonth() + 1;
        let yyyy = date.getFullYear();

        if (dd < 10) {
            dd = '0' + dd;
        }
        if (mm < 10) {
            mm = '0' + mm;
        }
        return yyyy + "-" + mm + "-" + dd;
    };

    static getFilterComparator = (opsymbole) => {
        var x = null;
        switch(opsymbole){
            case '=':
                x = ' => ';
                break;
            case '>':
                x =  '.gt => ';
                break;
            case '<':
                x = '.lt => ';
                break;
            case '<=':
                x = '.lte => ';
                break;
            case '>=':
                x = '.gte => ';
                break;
            default:
                x = ' => '
        }
        return x;
    };

    static compileQuery(query, url, attrs, defaultOrder, model,defaultData,defaultFilter,searchModels,filterModels, grouping){

        let data = defaultData || {};

        data.attrs =attrs;
        data.page = query.page+1;
        data.size = query.pageSize;
        data.new_format = true;
        data.model = model;

        if(query.orderBy){
            data.order = [{"attr":query.orderBy.field, "by": query.orderDirection}];
        }else{
            data.order = defaultOrder;
        }

        if(query.search){
            data.search = query.search;
        }

        if(grouping){
            data.group = grouping
        }

        let f = [];
        if(defaultFilter) {
            f.push(defaultFilter);
        }
        if(searchModels)
            data.searchModels = searchModels;
        if(query.filters){
            query.filters.forEach((item)=>{
                f.push(":"+item.column.field+Utils.getFilterComparator(item.column.tableData.operator)+'\''+item.column.tableData.filterValue+'*\'')
            });
            data.query='{'+f.join(',')+'}';
        }
        if(filterModels){

        }
        // url += '?'+$.param(data);
        return url;
    };

    static getReturnUrl = (url, fromUrl = null) =>{
        if(fromUrl !== null){
            return fromUrl;
        }else{
            let tab = url.split('/');
            let _t = [];
            _t.push(tab[0]);
            _t.push(tab[1]);
            _t.push(tab[2]);
            return _t.join('/');
        }
    };

    static dateFormat = (n) => {
        if (!n)
            return '';
        if(n === 'none')
            return n;

        var opts = {};
        opts.day = "numeric";
        opts.year = "numeric";
        opts.month = "numeric";
        var lang = navigator.language || navigator.userLanguage;

        n = n.slice(0,10);
        let _tmp = n.split('-');

        let dd = _tmp[2];
        let mm = _tmp[1]-1;
        let yyyy = _tmp[0];
        var event = new Date(Date.UTC(yyyy, mm, dd));

        return event.toLocaleDateString(lang, opts);
    };

    static goBack = (_this) => {
        _this.props.history.goBack();
    };

    static confirmDeleteMessage = (text) => {
        return swal({
            title: "Are you sure?",
            text: text,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
    };

    static setGlobal(self) {
        this.GlobalState = self;
    };

    static onLoadFrame(self, params) {
        let pages = [];
        let pageList = [];
        let currentPage = null;

        var x = $('#reportFrame').contents().find('br').hide();
        var x = $('#reportFrame').contents().find('.jrPage');
        let domPages = $('#reportFrame').contents().find('.jrPage');
        if (domPages.length > 0) {
            domPages.each(function (idx) {
                pages[idx] = $(this);
                pageList[idx] = 'page ' + (idx + 1);
                if (idx > 0) {
                    $(this).hide();
                } else {
                    currentPage = $(this);
                }
            });
            let xy = $('#reportFrame').contents().find('body');
            $('#reportFrame')[0].style.height = '' + $('#reportFrame').contents().find('body').scrollHeight + 'px';
        } else {
            currentPage = true;
        }
        let domImages = $('#reportFrame').contents().find('img');
        domImages.each(function (idx) {

            let img = $(this);
            if (img) {
                var src = img[0].src;
                img[0].src = src.replace(/http[s]?:\/\/[\w.:\d]+/g, "http://api.lecket.gm") + "?profile_id=" + cookies.load('profileId');
            }


        });
        self.setState({pageList: pageList, pages: pages, currentPage: currentPage});
    };

    static loadReport(baseUrl, parts, params) {
        $.ajax({
            method: "GET",
            url: Utils.getReportUrl(baseUrl, parts) + '&format=text/html',
            type: 'HTML',
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            }, success: function (data) {

                $('#reportFrame').contents().find('html').html(data);
                setTimeout(function () {
                    Utils.onLoadFrame(Utils.GlobalState, params);
                }, 1000)

            }.bind(Utils.GlobalState),
            error: function (error) {
                alert(error.responseText);
            }
        });
    };

    static showReportUtility(name, baseUrl, parts, cookies, params) {
        Utils.GlobalState.setState({report: {view: true, name: name, baseUrl: baseUrl, parts: parts, params: params}});
        this.loadReport(baseUrl, parts, params)
    };

    static getReportUrl(baseUrl, parts) {
        return baseUrl + '&params=' + escape(JSON.stringify(parts.params)) + parts.sub + "&injects=" + JSON.stringify(parts.inject);
    }

}
