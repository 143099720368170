import React from "react";
import "antd/dist/antd.css";
import Grid from "@material-ui/core/Grid";
import InputComponent from "./InputComponent";
import Tooltip from "@material-ui/core/Tooltip";

const ImportComponent = () => {

    return (
        <div>
            <Tooltip title={<h2 style={{color:"#fff"}}><br/>You must have the schools' record entered before adding other details.<br/><br/><br/> Date formats eg: 12-Jan-2021</h2>}>
                        <span>
                            <i className="material-icons">
                                help_outline
                            </i>
                        </span>
            </Tooltip>
            <Grid container spacing={3}>
                <InputComponent title="Institution"/>
                <InputComponent title="Staff"/>
                <InputComponent title="Students"/>
                <InputComponent title="Programme"/>
                <InputComponent title="Curricula"/>
                <InputComponent title="Infrastructure"/>
                <InputComponent title="Scholarships"/>
                <InputComponent title="Laboratory"/>
            </Grid>
        </div>
    );
};

export default ImportComponent;
