import React from 'react';
import TextField from '@material-ui/core/TextField';
import {withStyles} from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import {Card} from '@material-ui/core';
import Utils from '../../utils/utils';
import {connect} from "react-redux";
import {addStudent} from "../../redux/actions/studentActions";
import MenuItem from "@material-ui/core/MenuItem";
import IndustryService from "../../services/industryService";

const styles = (theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
});

class AddIndustry extends React.Component {
    state = {
        newIndustry: {
            name: '',
            address: '',
            website: '',
            staff_count: '',
            sector: '',
            region: '',
            field: '',
            intership: '',
            email: ''
        }
    };

    // Method to update the values of the textfields
    handleChange = e => {
        let val = e.target.value;
        let industry = {...this.state.newIndustry};
        if (e.target.name === 'staff_count' && Utils.isValid(val)) {
            Utils.displayMessage("warning", "", "Only numbers allowed");
            return;
        }
        industry[e.target.name] = val;
        this.setState({
            newIndustry: industry
        });
    };

    // Method to clear the values of the textfields
    clearForm = () => {
        let _industryData = this.state.newIndustry;
        _industryData.name = '';
        _industryData.address = '';
        _industryData.website = '';
        _industryData.staff_count = '';
        _industryData.sector = '';
        _industryData.region = '';
        _industryData.email = '';
        _industryData.field = '';
        _industryData.intership = '';
        this.setState({
            newIndustry: _industryData
        });
        if(localStorage.getItem('stakeholderId')){
            this.goBack();
        }
    };

    // Method to validate the values of the textfields
    validateFields = () => {
        const {name, email, address, staff_count, sector, region} = this.state.newIndustry;

        if (name === '' || !Utils.validateText(name)) return 'name';
        if (email !== '' && !Utils.validateEmail(email)) return 'email';
        if (address === '' || !Utils.validateText(address)) return 'address';
        if (staff_count === '' || !Utils.validateText(staff_count)) return 'staff_count';
        if (sector !== '' && !Utils.validateText(sector)) return 'sector';
        if (region !== '' && !Utils.validateText(region)) return 'region';
    };

    // Method to set focus on to a text field if the value inserted in is invalid or being empy
    // when it is a required field
    setFocus = (field_id) => {
        Utils.displayMessage('error', 'Error', 'Please specify ' + field_id).then(r => r);
        document.getElementById(field_id).focus();
    };

    getReturnedUser = (data) =>{
        this.clearForm();
    };

    // Method that sends data to the server to be saved
    saveData = () => {

        let data = this.state.newIndustry;

        data.intership = data.intership === 'yes' ? 1 : 0;

        let result = this.validateFields();
        if (result) {
            this.setFocus(result);
            return;
        }
        IndustryService.createStakeholder(data, this.getReturnedUser);
    };

    // Initializes the select fields of the form
    initialiseSelectFields = () => {
        let _industry = {...this.state.newIndustry};
        _industry.sector = Utils.sector[0].value;
        _industry.region = Utils.lga[0].value;
        this.setState({
            newIndustry: _industry
        })
    };

    // Method to take you back to the previous page/view you were on
    goBack = () => {
        this.props.history.goBack();
    };

    // Method to populate the fields of the form when editing an Industry
    populateForm = data =>{
        let _data = data;
        _data.intership = _data.intership === 1 ? 'yes' : 'no';
        this.setState({newIndustry: _data});
    };

    componentDidMount() {
        if (this.props.location.stakeholderData) {
            let  _data = {...this.props.location.stakeholderData};
            localStorage.setItem('stakeholderId', _data.id);
            this.populateForm(_data);
        }else if(this.props.location.stakeholderData === undefined && localStorage.getItem('stakeholderId') !== null){
            IndustryService.loadStakeholder(localStorage.getItem('stakeholderId'), this.populateForm)
        }
        else if(this.props.location.stakeholderData === undefined && localStorage.getItem('stakeholderId') === null){
            this.initialiseSelectFields();
        }
    }

    render() {
        const {classes} = this.props;
        let industry = this.state.newIndustry;

        return (
            <div>
                <Card>
                    <div style={{marginLeft: 150}}>
                        <form className={classes.root} autoComplete="on">
                            <h2>{localStorage.getItem('stakeholderId') !== null ? "Edit Stakeholder" : "Add Stakeholder"}</h2>
                            <TextField
                                required
                                id="name"
                                name="name"
                                label="Name"
                                variant="outlined"
                                value={industry.name}
                                onChange={e => this.handleChange(e)}
                            />
                            <TextField
                                id="email"
                                name="email"
                                label="Email"
                                variant="outlined"
                                value={industry.email}
                                onChange={e => this.handleChange(e)}
                            />
                            <TextField
                                required
                                id="address"
                                name="address"
                                label="Address"
                                variant="outlined"
                                value={industry.address}
                                onChange={e => this.handleChange(e)}
                            />
                            <TextField
                                id="website"
                                name="website"
                                label="Website"
                                variant="outlined"
                                value={industry.website}
                                onChange={e => this.handleChange(e)}
                            />
                            <TextField
                                required
                                id="staff_count"
                                name="staff_count"
                                label="Number of Staff"
                                variant="outlined"
                                value={industry.staff_count}
                                onChange={e => this.handleChange(e)}
                            />
                            <TextField
                                required
                                id="sector"
                                name="sector"
                                select
                                label="Sector"
                                value={industry.sector}
                                onChange={(e) => this.handleChange(e)}
                                variant="outlined"
                                style={{
                                    border: 0,
                                    outline: 0
                                }}
                            >
                                {Utils.sector.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                required
                                id="region"
                                name="region"
                                select
                                label="Region"
                                value={industry.region}
                                onChange={(e) => this.handleChange(e)}
                                variant="outlined"
                                style={{
                                    border: 0,
                                    outline: 0
                                }}
                            >
                                {Utils.lga.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                id="field"
                                name="field"
                                select
                                label="Field"
                                variant="outlined"
                                value={industry.field}
                                onChange={e => this.handleChange(e)}
                                style={{
                                    border: 0,
                                    outline: 0
                                }}
                            >
                                {Utils.fieldsOfEducation.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                id="internship"
                                name="intership"
                                select
                                label="Offer Internships"
                                variant="outlined"
                                value={industry.intership}
                                onChange={e => this.handleChange(e)}
                                style={{
                                    border: 0,
                                    outline: 0
                                }}
                            >
                                <MenuItem key='yes' value='yes'>Yes</MenuItem>
                                <MenuItem key='no' value='no'>No</MenuItem>
                            </TextField>

                        </form>
                    </div>
                    <div style={{padding: 5}}>
                        <Button variant="contained" color="secondary" onClick={this.goBack} style={{margin: 5}}>
                            Cancel
                        </Button>
                        <Button variant="contained" color="primary" onClick={this.saveData} style={{margin: 5}}>
                            Save
                        </Button>
                    </div>
                </Card>
            </div>
        );
    }
}

export default connect(null, {addStudent})(withStyles(styles)(AddIndustry));


