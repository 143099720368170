import React from 'react';
import TextField from '@material-ui/core/TextField';
import {withStyles} from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import {Card, Grid} from '@material-ui/core';
import Utils from "../../utils/utils";
import MenuItem from "@material-ui/core/MenuItem";
import VacancyService from "../../services/vacancyService";
import * as cookies from "react-cookies";

const styles = (theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
});

class AddVacancy extends React.Component {
    state = {
        vacancy: {
            position: '',
            field_of_education: '',
            org_id: '',
            region: '',
            job_type: '',
            department: '',
            description: '',
            dead_line: '',
            app_start_date: '',
            qualification: '',
        },

        educationFields: []
    };

    // Method to clear values of the text fields
    clearForm = () => {
        let _vacancy = {...this.state.vacancy};

        _vacancy.position = '';
        _vacancy.field_of_education = '';
        _vacancy.region = '';
        _vacancy.type = '';
        _vacancy.field_of_education = '';
        _vacancy.dead_line  = Utils.getToday();
        _vacancy.app_start_date = Utils.getToday();
        _vacancy.qualification = '';

        this.setState({
            vacancy: _vacancy
        });
    };

    // Method to update values of the text fields
    handleChange = (e) => {
        let _vacancy = {...this.state.vacancy};
        let name = e.target.name;
        _vacancy[name] = e.target.value;
        this.setState({
            vacancy: _vacancy
        });
    };

    // Method to validate values of the text fields
    validateFields = () => {
        const {position, field_of_education} = this.state.vacancy;

        if (position === '') return 'position';
        if (field_of_education === '') return 'field of education';
    };

    // Method to get the different education field names
    loadFields = () => {
        VacancyService.loadEducationFields(result => {
            if (result.fields) {
                let _fields = result.fields;
                let temp = [];
                if (_fields.length > 0) {
                    _fields.forEach(field => {
                        temp.push({label: field, value: field});
                    });
                }
                this.setState({
                    educationFields: temp
                });
            }
        })
    };

    /*
    * Method to set focus on the text fields with invalid data
    * Parameters:
    * + field_id: The id of the field to set the focus on
    * */
    setFocus = (field_id) => {
        if (field_id === 'field of education') {
            field_id = 'field_of_education';
        }
        Utils.displayMessage('error', 'Error', 'Please specify ' + field_id).then(r => r);
        document.getElementById(field_id).focus();
    };

    // Method to send and save data to the server
    saveData = () => {
        let _vacancy = {...this.state.vacancy};
        let result = this.validateFields();
        if (result) {
            this.setFocus(result);
            return;
        }

        VacancyService.createVacancy(_vacancy, result => {
            if (result.error) {
                Utils.displayMessage('error', 'Failed', result.errors[0]).then(r => r);
            } else {
                Utils.displayMessage('success', 'Successful', result.success).then(r => r);
                this.goBack();
            }
        })
    };

    // Initializes the select field values of the form
    initialiseFields = () => {
        let _vacancy = {...this.state.vacancy};
        _vacancy.dead_line = Utils.getToday();
        _vacancy.app_start_date = Utils.getToday();
        _vacancy.org_id = cookies.load('orgId');

        this.setState({
            vacancy: _vacancy
        });
    };

    // Method to navigate back to the previous component/view
    goBack = () => {
        this.clearForm();
        this.props.history.goBack();
    };

    /*
    * Method to populate the text field values when editing a vacancy / initialize fields when adding a new vacancy
    * Parameters:
    * + data: The data of the user to be edited
    * */
    getFieldsData = () => {
        let _vacancy = {};
        if(localStorage.getItem('vacancyData') !== null){
            _vacancy = {...JSON.parse(localStorage.getItem('vacancyData'))};
            _vacancy.org_id = _vacancy.organization_id;
            this.setState({
                vacancy: _vacancy
            })
        }else{
            this.initialiseFields();
        }
    };

    componentDidMount() {
        if(this.props.location.vacancyData !== undefined){
            localStorage.setItem('vacancyData',JSON.stringify(this.props.location.vacancyData));
        }
        this.loadFields();
        this.getFieldsData();
    }

    render() {
        const {classes} = this.props;
        let _vacancy = this.state.vacancy;

        return (
            <div>
                <Card>
                    <div style={{marginLeft: 150}}>
                        <form className={classes.root} autoComplete="on">
                            {
                                (this.props.location.vacancyData) ? <h2>Edit Vacancy</h2> :
                                    <h2>New Vacancy</h2>
                            }
                            <Grid container>
                                <Grid item xs={12} sm={12} md={12}>
                                    <TextField
                                        required
                                        id="position"
                                        name="position"
                                        label="Position"
                                        variant="outlined"
                                        value={_vacancy.position}
                                        onChange={e => this.handleChange(e)}
                                    />
                                    <TextField
                                        id="app_start_date"
                                        name="app_start_date"
                                        label="Application Start Date"
                                        variant="outlined"
                                        value={_vacancy.app_start_date}
                                        onChange={e => this.handleChange(e)}
                                        type="date"
                                    />
                                    <TextField
                                        id="dead_line"
                                        name="dead_line"
                                        label="Deadline"
                                        variant="outlined"
                                        value={_vacancy.dead_line}
                                        onChange={e => this.handleChange(e)}
                                        type="date"
                                    />
                                    <TextField
                                        required
                                        id="field_of_education"
                                        name="field_of_education"
                                        select
                                        label="Field Of Education"
                                        value={_vacancy.field_of_education}
                                        onChange={e => this.handleChange(e)}
                                        variant="outlined"
                                        style={{
                                            border: 0,
                                            outline: 0
                                        }}
                                    >
                                        {this.state.educationFields.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <TextField
                                        required
                                        id="region"
                                        name="region"
                                        select
                                        label="Region"
                                        value={_vacancy.region}
                                        onChange={(e) => this.handleChange(e)}
                                        variant="outlined"
                                        style={{
                                            border: 0,
                                            outline: 0
                                        }}
                                    >
                                        {Utils.lga.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <TextField
                                        id="job_type"
                                        name="job_type"
                                        select
                                        label="Job Type"
                                        value={_vacancy.job_type}
                                        onChange={e => this.handleChange(e)}
                                        variant="outlined"
                                        style={{
                                            border: 0,
                                            outline: 0
                                        }}
                                    >
                                        {Utils.vacancyJobTypes.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <TextField
                                        id="qualification"
                                        name="qualification"
                                        select
                                        label="Qualification"
                                        value={_vacancy.qualification}
                                        onChange={e => this.handleChange(e)}
                                        variant="outlined"
                                        style={{
                                            border: 0,
                                            outline: 0
                                        }}
                                    >
                                        {Utils.qualifications.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>

                                </Grid>
                            </Grid>
                        </form>
                    </div>
                    <div style={{padding: 5}}>
                        <Button variant="contained" color="secondary" onClick={this.goBack} style={{margin: 5}}>
                            Cancel
                        </Button>
                        <Button variant="contained" color="primary" onClick={this.saveData} style={{margin: 5}}>
                            Save
                        </Button>
                    </div>
                </Card>
            </div>
        );
    }
}

export default withStyles(styles)(AddVacancy);


