import {People, Dashboard, InsertChart, School, BusinessCenter, Assignment, ImportExport} from '@material-ui/icons';
import Users from "../containers/Users/Users";
import DashboardComponent from "../containers/Dashboard/AdminDashboard";
import Analyses from "../containers/Analyses/Analyses";
import Institutions from "../containers/Institutions/Institutions";
import Industry from "../containers/Industry/Industry";
import MainReport from "../containers/Reports/MainReport";
import ImportComponent from "../components/ImportComponent/ImportComponent";

const dashboard =  {
    path: "/",
    name: "Dashboard",
    icon: Dashboard,
    component: DashboardComponent
};

const users =  {
    path: "/users",
    name: "Users",
    icon: People,
    component: Users
};

const analytics =  {
    path: "/analytics",
    name: "Analytics",
    icon: InsertChart,
    component: Analyses
};

const institutions =  {
    path: "/institutions",
    name: "Institutions",
    icon: School,
    component: Institutions
};

const industry =  {
    path: "/industry",
    name: "Industry",
    icon: BusinessCenter,
    component: Industry,
};

const reports =  {
    path: "/reports",
    name: "Reports",
    icon: Assignment,
    component: MainReport
};

const import_data =  {
    path: "/import-data",
    name: "Import Data",
    icon: ImportExport,
    component: ImportComponent
};


const superAdminRoutes = [dashboard, users, analytics, institutions, industry, reports, import_data];

export default superAdminRoutes;
