import { FETCHING_REQUEST, FETCH_SUCCESS, FETCH_ERROR } from "../actionTypes/appActionTypes";

const initialState = {
    isFetching: true,
    error: ''
};

export default function(state = initialState, action) {
    switch (action.type) {
        case FETCHING_REQUEST: {

            return Object.assign({}, state, {
                isFetching: true
            });
        }
        case FETCH_SUCCESS: {

            return Object.assign({}, state, {
                isFetching: false
            });
        }
        default:
            return state;
    }
}
