import $ from "jquery";
import Utils from "../utils/utils";
import { Api } from "../config/api_config";
import cookies from "react-cookies";

export default class LcService {
  // Method that sends data to create or update a school
  // If creating a new school, the method is POST
  // If updating a school, the method is PUT
  static create(data, callback) {
    const id = data.id ? data.id : "";
    const _method = id ? "PUT" : "POST";

    $.ajax({
      method: _method,
      url: Api.DEV_BASE_URL + "/v1/schools/" + id,
      data: data,
      success: function (result) {
        callback(result);
      },
      error: function (e) {
        Utils.displayMessage(
          "error",
          "Failed",
          "Learining Center could not be saved"
        );
      },
    });
  }

  // Method to loads schools depending on the classification of the school
  static loadSchools = (classification, callback) => {
    $.ajax({
      method: "GET",
      url: Api.DEV_BASE_URL + "/v1/schools",
      dataType: "json",
      headers: {
        Authorization: "Bearer " + cookies.load("token"),
      },
      data: { classification: classification },
      success: function (result) {
        callback(result);
      },
      error: function (e) {},
    });
  };

  static sync = () => {
    $.ajax({
      method: "POST",
      url: Api.DEV_BASE_URL + "/v1/students/sync",
      dataTYpe: "JSON",
      success: (res) => {
        console.log("Successful sync", res);
      },
      error: function (error) {
        console.log("Error syncing", error);
      },
    });
  };

  static deleteInstitution = (id, callback) => {
    $.ajax({
      method: "DELETE",
      url: Api.DEV_BASE_URL + "/v1/schools/" + id,
      dataTYpe: "JSON",
      headers: {
        Authorization: "Bearer " + cookies.load("token"),
      },
      success: (res) => {
        callback(null, res);
      },
      error: function (error) {
        callback(error, null);
      },
    });
  };

  /*
        Method to load the details of a school
        Parameters:
            + id: The id of the school to load details for
            + callback: Method to handle the response returned from the server
     */
  static loadSchool = (id, callback) => {
    $.ajax({
      method: "GET",
      url: Api.DEV_BASE_URL + "/v1/schools/" + id,
      dataType: "json",
      headers: {
        Authorization: "Bearer " + cookies.load("token"),
      },
      success: function (result) {
        callback(result);
      },
      error: function (e) {
        Utils.displayMessage("error", "Failed", "School could not be loaded");
      },
    });
  };

  /*
       Method to load the students of a school
       Parameters:
           + schoolId: The id of the school to load students for
           + callback: Method to handle the response returned from the server
    */
  static loadStudents = (schoolId, callback) => {
    $.ajax({
      method: "GET",
      url: Api.DEV_BASE_URL + "/v1/schools/" + schoolId + "/enrollments",
      dataType: "json",
      headers: {
        Authorization: "Bearer " + cookies.load("token"),
      },
      success: function (result) {
        callback(result);
      },
      error: function (e) {
        Utils.displayMessage("error", "Failed", "Students could not be loaded");
      },
    });
  };

  /*
      Method to load the details of a student of a school
      Parameters:
            + id: The id of the student to load details for
            + schoolId: The id of the school to load students for
            + callback: Method to handle the response returned from the server
   */
  static loadStudent = (schoolId, id, callback) => {
    $.ajax({
      method: "GET",
      url: Api.DEV_BASE_URL + "/v1/schools/" + schoolId + "/enrollments/" + id,
      dataType: "json",
      headers: {
        Authorization: "Bearer " + cookies.load("token"),
      },
      success: function (result) {
        callback(result);
      },
      error: function (e) {
        Utils.displayMessage(
          "error",
          "Failed",
          "Student data could not be loaded"
        );
      },
    });
  };

  /*
       Method to load the all of the staff of a school
       Parameters:
         + schoolId: The id of the school to load staff for
         + callback: Method to handle the response returned from the server
    */
  static loadAllStaff = (schoolId, callback) => {
    $.ajax({
      method: "GET",
      url: Api.DEV_BASE_URL + "/v1/schools/" + schoolId + "/staff",
      dataType: "json",
      headers: {
        Authorization: "Bearer " + cookies.load("token"),
      },
      success: function (result) {
        callback(result);
      },
      error: function (e) {
        Utils.displayMessage("error", "Failed", "Students could not be loaded");
      },
    });
  };

  /*
        Method to load the details of a staff of a school
        Parameters:
         + id: The id of the staff to load details for
         + schoolId: The id of the school to load details staff for
         + callback: Method to handle the response returned from the server
    */
  static loadStaff = (schoolId, id, callback) => {
    $.ajax({
      method: "GET",
      url: Api.DEV_BASE_URL + "/v1/schools/" + schoolId + "/staff/" + id,
      dataType: "json",
      headers: {
        Authorization: "Bearer " + cookies.load("token"),
      },
      success: function (result) {
        callback(result);
      },
      error: function (e) {
        Utils.displayMessage(
          "error",
          "Failed",
          "Staff data could not be loaded"
        );
      },
    });
  };

  /*
         Method to load the list of a programmes of a school
         Parameters:
            + schoolId: The id of the school to load programmes for
            + callback: Method to handle the response returned from the server
    */
  static loadProgrammes = (schoolId, callback) => {
    $.ajax({
      method: "GET",
      url: Api.DEV_BASE_URL + "/v1/schools/" + schoolId + "/programmes",
      dataType: "json",
      headers: {
        Authorization: "Bearer " + cookies.load("token"),
      },
      success: function (result) {
        callback(result);
      },
      error: function (e) {
        Utils.displayMessage(
          "error",
          "Failed",
          "Programmes could not be loaded"
        ).then((r) => r);
      },
    });
  };

  /*
       Method to load the details of a programme of a school
       Parameters:
        + progId: The id of the programme to load details for
        + schoolId: The id of the school to load programme details for
        + callback: Method to handle the response returned from the server
   */
  static loadSchoolProgram = (schoolId, progId, callback) => {
    $.ajax({
      method: "GET",
      url:
        Api.DEV_BASE_URL + "/v1/schools/" + schoolId + "/programmes/" + progId,
      dataType: "json",
      headers: {
        Authorization: "Bearer " + cookies.load("token"),
      },
      success: function (result) {
        callback(result);
      },
      error: function (e) {
        Utils.displayMessage(
          "error",
          "Failed",
          "Programme could not be loaded"
        ).then((r) => r);
      },
    });
  };

  /*
       Method to load the students of a programme of a school
       Parameters:
        + progId: The id of the programme to load students for
        + schoolId: The id of the school to load programme students for
        + callback: Method to handle the response returned from the server
   */
  static loadProgramStudents = (schoolId, progId, callback) => {
    $.ajax({
      method: "GET",
      url:
        Api.DEV_BASE_URL +
        "/v1/schools/" +
        schoolId +
        "/programmes/" +
        progId +
        "/get_students",
      dataType: "json",
      headers: {
        Authorization: "Bearer " + cookies.load("token"),
      },
      success: function (result) {
        callback(result);
      },
      error: function (e) {
        Utils.displayMessage(
          "error",
          "Failed",
          "Programme could not be loaded"
        ).then((r) => r);
      },
    });
  };

  /*
       Method to load the different year options of admissions of all schools
       Parameters:
        + callback: Method to handle the response returned from the server
   */
  static getYears = (callback) => {
    $.ajax({
      method: "GET",
      url: Api.DEV_BASE_URL + "/v1/schools/load_year_options/",
      dataType: "json",
      headers: { Authorization: "Bearer " + cookies.load("token") },
      success: function (result) {
        console.log({ result });
        callback(result);
      },
      error: function (e) {},
    });
  };

  /*
      Method to load the different programme names offered in schools
      Parameters:
       + callback: Method to handle the response returned from the server
    */
  static getProgramNames = (callback) => {
    $.ajax({
      method: "GET",
      url: Api.DEV_BASE_URL + "/v1/schools/load_prog_names/",
      dataType: "json",
      headers: { Authorization: "Bearer " + cookies.load("token") },
      success: function (result) {
        callback(result);
      },
      error: function (e) {},
    });
  };

  /*
     Method to load the different scholarship awarding body names offered in schools
     Parameters:
      + callback: Method to handle the response returned from the server
   */
  static getScholarshipNames = (callback) => {
    $.ajax({
      method: "GET",
      url: Api.DEV_BASE_URL + "/v1/schools/scholarship_names/",
      dataType: "json",
      headers: { Authorization: "Bearer " + cookies.load("token") },
      success: function (result) {
        callback(result);
      },
      error: function (e) {},
    });
  };

  static importStaff = (data, callback) => {
    let orgId = null;
    if (cookies.load("user_type") === "school-admin") {
      orgId = cookies.load("orgId");
    }

    $.ajax({
      method: "POST",
      url: Api.DEV_BASE_URL + "/application/import_staff",
      dataType: "json",
      headers: {
        Authorization: "Bearer " + cookies.load("token"),
      },
      data: { data, orgId },
      success: function (result) {
        callback(result);
      },
      error: function (e) {
        Utils.displayMessage(
          "error",
          "Failed",
          "Staff data could not be imported"
        ).then((r) => r);
      },
    });
  };

  static importInstitution = (data, callback) => {
    let orgId = null;
    if (cookies.load("user_type") === "school-admin") {
      orgId = cookies.load("orgId");
    }

    $.ajax({
      method: "POST",
      url: Api.DEV_BASE_URL + "/application/import_institution",
      dataType: "json",
      headers: {
        Authorization: "Bearer " + cookies.load("token"),
      },
      data: { data, orgId },
      success: function (result) {
        callback(result);
      },
      error: function (e) {
        Utils.displayMessage(
          "error",
          "Failed",
          "Institution data could not be imported"
        ).then((r) => r);
      },
    });
  };

  static importStudents = (data, callback) => {
    let orgId = null;
    if (cookies.load("user_type") === "school-admin") {
      orgId = cookies.load("orgId");
    }

    $.ajax({
      method: "POST",
      url: Api.DEV_BASE_URL + "/application/import_students",
      dataType: "json",
      headers: {
        Authorization: "Bearer " + cookies.load("token"),
      },
      data: { data, orgId },
      success: function (result) {
        callback(result);
      },
      error: function (e) {
        Utils.displayMessage(
          "error",
          "Failed",
          "Students data could not be imported"
        ).then((r) => r);
      },
    });
  };

  static importProgrammes = (data, callback) => {
    let orgId = null;
    if (cookies.load("user_type") === "school-admin") {
      orgId = cookies.load("orgId");
    }
    $.ajax({
      method: "POST",
      url: Api.DEV_BASE_URL + "/application/import_programmes",
      dataType: "json",
      headers: {
        Authorization: "Bearer " + cookies.load("token"),
      },
      data: { data, orgId },
      success: function (result) {
        callback(result);
      },
      error: function (e) {
        Utils.displayMessage(
          "error",
          "Failed",
          "Programs data could not be imported"
        ).then((r) => r);
      },
    });
  };

  static importCurricula = (data, callback) => {
    let orgId = null;
    if (cookies.load("user_type") === "school-admin") {
      orgId = cookies.load("orgId");
    }
    $.ajax({
      method: "POST",
      url: Api.DEV_BASE_URL + "/application/import_curricula",
      dataType: "json",
      headers: {
        Authorization: "Bearer " + cookies.load("token"),
      },
      data: { data, orgId },
      success: function (result) {
        callback(result);
      },
      error: function (e) {
        Utils.displayMessage(
          "error",
          "Failed",
          "Curricula data could not be imported"
        ).then((r) => r);
      },
    });
  };

  static importInfrastructure = (data, callback) => {
    let orgId = null;
    if (cookies.load("user_type") === "school-admin") {
      orgId = cookies.load("orgId");
    }
    $.ajax({
      method: "POST",
      url: Api.DEV_BASE_URL + "/application/import_infrastructure",
      dataType: "json",
      headers: {
        Authorization: "Bearer " + cookies.load("token"),
      },
      data: { data, orgId },
      success: function (result) {
        callback(result);
      },
      error: function (e) {
        Utils.displayMessage(
          "error",
          "Failed",
          "Infrastructure data could not be imported"
        ).then((r) => r);
      },
    });
  };

  static importSchoolLab = (data, callback) => {
    let orgId = null;
    if (cookies.load("user_type") === "school-admin") {
      orgId = cookies.load("orgId");
    }

    $.ajax({
      method: "POST",
      url: Api.DEV_BASE_URL + "/application/import_laboratory",
      dataType: "json",
      headers: {
        Authorization: "Bearer " + cookies.load("token"),
      },
      data: { data, orgId },
      success: function (result) {
        callback(result);
      },
      error: function (e) {
        Utils.displayMessage(
          "error",
          "Failed",
          "School lab(s) data could not be imported"
        ).then((r) => r);
      },
    });
  };

  static importScholarships = (data, callback) => {
    let orgId = null;
    if (cookies.load("user_type") === "school-admin") {
      orgId = cookies.load("orgId");
    }

    $.ajax({
      method: "POST",
      url: Api.DEV_BASE_URL + "/application/import_scholarships",
      dataType: "json",
      headers: {
        Authorization: "Bearer " + cookies.load("token"),
      },
      data: { data, orgId },
      success: function (result) {
        callback(result);
      },
      error: function (e) {
        Utils.displayMessage(
          "error",
          "Failed",
          "School lab(s) data could not be imported"
        ).then((r) => r);
      },
    });
  };
}
