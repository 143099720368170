import $ from "jquery";
import Utils from "../utils/utils";

import {Api} from "../config/api_config";
import cookies from 'react-cookies';

export default class IndustryService {

    // Method that requests to save data of an organizations of the type stakeholder
    static createStakeholder(data, callback){
        const id = (data.id) ? data.id : '';
        const _method = (id) ? "PUT" : "POST";

        $.ajax({
            method: _method,
            url: Api.DEV_BASE_URL+"/v1/organizations/"+id,
            headers: {
                "Authorization": "Bearer " + cookies.load("token")
            },
            data: data,
            success: function (result) {
                callback(result);
            },
            error:function (e)  {
                if(e.error){
                    Utils.displayMessage('error', 'Failed', e.message).then(r => r);
                }
            }
        })
    }

    // Method that requests to load all organizations of the type stakeholders
    static loadStakeholders = (callback) =>{
        $.ajax({
            method: "GET",
            url: Api.DEV_BASE_URL+"/v1/organizations",
            dataType: "json",
            headers: {
                "Authorization": "Bearer " + cookies.load("token")
            },
            success: function(result) {
                callback(result);
            },
            error: function(e){
                Utils.displayMessage('error', 'Failed', 'Stakeholders could not be loaded').then(r => r)
            }
        });
    };

    // Method to load the details of a stakeholder
    static loadStakeholder = (id, callback) =>{
        $.ajax({
            method: "GET",
            url: Api.DEV_BASE_URL+"/v1/organizations/"+id,
            dataType: "json",
            headers: {
                "Authorization": "Bearer " + cookies.load("token")
            },
            success: function(result) {
                callback(result.data);
            },
            error: function(e){
                Utils.displayMessage('error', 'Failed', 'Stakeholder details could not be loaded').then(r => r)
            }
        });
    };
}
