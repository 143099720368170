import {Assignment, BusinessCenter, Dashboard, Equalizer, InsertChart, School} from '@material-ui/icons';
import Institutions from "../containers/Institutions/Institutions";
import Industry from "../containers/Industry/Industry";
import DashboardComponent from "../containers/Dashboard/Dashboard";
import Analyses from "../containers/Analyses/Analyses";
import MainReport from "../containers/Reports/MainReport";

const dashboard = {
    path: "/",
    name: "Dashboard",
    icon: Dashboard,
    component: DashboardComponent
};

const analytics = {
    path: "/analytics",
    name: "Analytics",
    icon: InsertChart,
    component: Analyses
};

const institutions = {
    path: "/institutions",
    name: "Institutions",
    icon: School,
    component: Institutions
};

const industry = {
    path: "/industry",
    name: "Industry",
    icon: BusinessCenter,
    component: Industry,
};

const reports = {
    path: "/reports",
    name: "Reports",
    icon: Assignment,
    component: MainReport
};

const analystRoutes = [dashboard, analytics, institutions, industry,reports];

export default analystRoutes;
