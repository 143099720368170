import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Orgs from "./Orgnization";
import User from "./User";
import Utils from "../../utils/utils";
import UserService from "../../services/userService";
import IndustryService from "../../services/industryService";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

const Register = (props) => {
    const classes = useStyles();
    const [activeStep, setActiveStep] = useState(0);
    const [userRoles, setUserRoles] = useState([]);
    const [orgData, setOrgData] = useState({
        name: '',
        address: '',
        website: '',
        staff_count: '',
        sector: '',
        region: '',
        field: '',
        intership: '',
        email: '',
        org_type: '',
        registering: true
    });
    const [userData, setUserData] = useState({
        first_name: '',
        middle_names: '',
        last_name: '',
        email: '',
        password: '',
        password_confirmation: '',
        org: '',
        role: '',
        recaptchaValue: '',
    });
    const [recaptchaValue, setRecaptchaValue] = useState('');

    useEffect(() => {
        getUserRoles();
    }, []);

    // Method to update the user's data fields
    const updateUserState = (name, val) => {
        let _data = {...userData};
        _data[name] = val;

        setUserData(_data);
    };

    // Method to update the organization's data fields
    const updateOrgState = (name, val) => {
        let _data = {...orgData};
        if (name === 'staff_count' && Utils.isValid(val)) {
            Utils.displayMessage("warning", "", "Only numbers allowed").then(r => r);
            return;
        }
        _data[name] = val;
        setOrgData(_data);
    };

    // Method to validate the values of the organization's data fields
    const validateOrgFields = () => {
        const {name, email, address, sector, region, org_type} = orgData;

        if (name === '' || !Utils.validateText(name)) return 'name';
        if (email !== '' && !Utils.validateEmail(email)) return 'email';
        if (address === '' || !Utils.validateText(address)) return 'address';
        if (sector !== '' && !Utils.validateText(sector)) return 'sector';
        if (region === '' || (region !== '' && !Utils.validateText(region))) return 'region';
        if (org_type === '') return 'org_type';
        return false;
    };

    // Method to validate the values of the user's data fields
    const validateUserFields = () => {
        const {first_name, middle_names, last_name, password, password_confirmation, email} = userData;

        if (first_name === '' || !Utils.validateText(first_name)) return 'first_name';
        if (middle_names !== '' && !Utils.validateText(middle_names)) return 'middle_names';
        if (last_name === '' || !Utils.validateText(last_name)) return 'last_name';
        if (password === '' && !Utils.validateText(password)) return 'password';
        if (password_confirmation === '' && !Utils.validateText(password_confirmation)) return 'password_confirmation';
        if (email === '' && !Utils.validateEmail(email)) return 'email';

        return false;
    };

    /*
        Method to navigate user to the next step of the registration or
        to send registration data to the server to be save
     */
    const handleNext = () => {
        if (activeStep < 1) {
            let res = validateOrgFields();
            if (res !== false && res.indexOf('_') > -1) {
                let _res = res.split('_');
                res = _res[0] + " " + _res[1];
            }
            if (res === false) {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            } else {
                Utils.displayMessage('error', 'Failed', `Please specify ${res}`).then(r => r);
            }
        } else {
            let res = validateUserFields();
            if (res !== false && res.indexOf('_') > -1) {
                let _res = res.split('_');
                res = _res[0] + " " + _res[1];
            }
            if (res === false) {
                saveData();
            } else {
                Utils.displayMessage('error', 'Failed', `Please specify ${res}`).then(r => r);
            }
        }
    };

    // Method to load the different user roles
    const getUserRoles = () => {
        UserService.getRoles(response => {
            if (response.data) {
                setUserRoles(response.data)
            }
        });
    };

    // Method to get a specific user role
    const getRole = (userRole) => {
        let _role = "";
        if (userRole === "Industry") _role = "industry";
        if (userRole === "Partner") _role = "partner";
        if (userRole === "Stakeholder") _role = "analyst";

        return userRoles.filter(role => role.name === _role)[0].id;
    };

    // Method to save data to the server
    const saveData = () => {
        let _orgData = {...orgData};
        let _userData = {...userData};
        _orgData.intership = _orgData.intership === 'yes' ? 1 : 0;
        _userData.role = getRole(_orgData.org_type);
        let data = {org: _orgData, user: _userData};

        IndustryService.createStakeholder(data, result => {
            if (result.error) {
                Utils.displayMessage('error', 'Failed', result.errors[0]).then(r => r);
            } else {
                Utils.displayMessage('success', 'Successful', result.message).then(r => r);
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
        });
    };

    // Method to navigate you back to the previous component/view
    const handleBack = () => {
        if (activeStep === 1) {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        } else {
            props.history.push('/login');
        }
    };

    // Method to get the next view to be display in the registration wizard
    const getSteps = () => {
        return ['organization Information', 'User Information'];
    };

    // Method that navigates the user to the login component after registering
    const directToLogin = () => {
        props.history.push('/login');
    };

    // Method to update the recaptcha value
    const updateRecaptcha = (val) => {
        setRecaptchaValue(val);
    };

    // Method to return the component to be displayed based on the step you are on
    const getStepContent = (stepIndex) => {
        switch (stepIndex) {
            case 0:
                return <Orgs orgData={orgData} updateData={updateOrgState}/>;
            case 1:
                return <User userData={userData} updateData={updateUserState} updateRecaptcha={updateRecaptcha}/>;
            default:
                return 'Unknown stepIndex';
        }
    };

    const steps = getSteps();

    return (
        <div className={classes.root}>
            <h4 style={{textAlign: 'center'}}>Registration Page</h4>
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <div>
                {activeStep === steps.length ? (
                    <div>
                        <Typography className={classes.instructions}>Registration completed, contact your admin to
                            activate your account.</Typography>
                        <Button onClick={directToLogin}>Login</Button>
                    </div>
                ) : (
                    <div>
                        <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
                        <div style={{textAlign: 'center'}}>
                            <Button
                                onClick={handleBack}
                                className={classes.button}
                                color="secondary"
                                variant="contained"
                                style={{marginRight: 5}}
                            >
                                Cancel
                            </Button>
                            {/*<Button variant="contained" color="primary" onClick={handleNext} disabled={enableButton}>*/}
                            {/*    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}*/}
                            {/*</Button>*/}
                            <Button variant="contained" color="primary" onClick={handleNext}>
                                {activeStep === steps.length - 1 ? 'Save' : 'Next'}
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Register;
