import React, {useEffect, useState} from 'react';
import Grid from '@material-ui/core/Grid';
import {Paper} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import UsersDashboard from "./Users_Dashboard";
import UserService from "../../services/userService";
import Utils from "../../utils/utils";

const Dashboard = () => {

    const headers = ['User', 'Logged In From', 'Last Logged In Date'];
    const [users, setUsers] = useState([]);

    // Method called when componentMount to get the list of users
    useEffect(() => {
        UserService.getUsers(response => {
            if (response.data) {
                setUsers(response.data);
            }
        });
    }, []);

    return (

        <div>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={5}>
                    <UsersDashboard/>
                </Grid>
                <Grid item xs={12} md={6} lg={7}>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    {headers.map((header) => (

                                        <TableCell align="left">{header}</TableCell>

                                    ))}
                                </TableRow>

                            </TableHead>
                            <TableBody>
                                {users.map((event) => (
                                    <TableRow>
                                        <TableCell align="left">{event.email}</TableCell>
                                        <TableCell align="left">{event.recall_ip}</TableCell>
                                        <TableCell align="left">{Utils.dateFormat(event.last_login_date)}</TableCell>
                                    </TableRow>
                                ))}

                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </div>
    );
};

export default Dashboard;
