import React,{Component} from 'react';
import SimpleView from "../SimpleView/SimpleView";
import IndustryService from "../../services/industryService";
import Utils from "../../utils/utils";
import ProgressBar from "../ProgressBar/ProgressBar";

class Industry extends Component{
    constructor(props){
        super(props);

        this.state = {
            data: [],
            displayLoader: false
        }
    };

    // Method to update the list of industries when loaded from the server
    setUpData = (result) => {
        if(result.data){
            this.setState({
                data: result.data,
                displayLoader: false
            });
        }else{
            Utils.displayMessage('error', 'Failed', result.errors).then(r => r);
        }
    };

    componentDidMount() {
        this.setState({
            displayLoader: true
        });
        IndustryService.loadStakeholders(this.setUpData);
        localStorage.clear();
    }

    render(){

        const data = {
            name: 'External Stakeholders',
            addButton: 'New Stakeholder',
            buttonPath: '/new-industry',
            headers: [
                { title: 'Name', field: 'name' },
                { title: 'Address', field: 'address' },
                { title: 'Area of Interest', field: 'field' },
                { title: 'Region', field: 'region' },
                { title: 'Ownership', field: 'sector'},
                { title: 'Status', field: 'status'}
            ],
            data: this.state.data
        };
        return(
            <>
                {
                    this.state.displayLoader ? <ProgressBar/> : null
                }
                <SimpleView data={data}/>
            </>
        );
    }
}

export default Industry;
