import React, {useEffect, useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import $ from "jquery";
import Utils from "../../utils/utils";
import FormControl from "@material-ui/core/FormControl";
import clsx from "clsx";
import InputAdornment from "@material-ui/core/InputAdornment";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import {Api} from "../../config/api_config";


function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://material-ui.com/">
                GEMIS
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const ResetPasswordForm = (props) => {
    const classes = useStyles();
    const [credentials, setCredentials] = useState({
        email: '',
        password: '',
        confirmPassword: ''
    });

    const [displayPassword, setDisplayPassword] = useState(false);
    const [displayConPassword, setDisplayConPassword] = useState(false);

    const handleChange = (e) => {
        let creds = {...credentials};
        creds[e.target.name] = e.target.value;
        setCredentials(creds);
    };

    useEffect(() => {
        let creds = {...credentials};
        creds.email = props.location.search.split('&')[1].split('=')[1];
        setCredentials(creds);
    }, []);

    const resetPassword = (e) => {
        e.preventDefault();
        const {email, password, confirmPassword} = credentials;
        if (password !== confirmPassword) {
            Utils.displayMessage('error', 'Password mismatch', 'Passwords do not match').then(r => r);
        }else{
            let r = Math.random().toString(36);
            $.ajax({
                method: "PUT",
                url: Api.DEV_BASE_URL + "/v1/users/reset_forgotten_password",
                headers: {"UserKey": r},
                data: {email: email, "new_password": password, "confirm_password": confirmPassword},
                dataType: "json",
                success: function (result) {
                    if (result.error) {
                        Utils.displayMessage('error', 'Failed', result.errors[0]).then(r => r);
                    } else {
                        Utils.displayMessage('success', 'Successful', result.success).then(r => r);
                        props.history.push('/login');
                    }
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    Utils.displayMessage('error', 'Failed', 'Wrong username or password').then(r => r);
                }
            });
        }
    };

    const handleClickShowPassword = () => {
        setDisplayPassword(!displayPassword);
    };

    const handleClickShowConPassword = () => {
        setDisplayConPassword(!displayConPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon/>
                </Avatar>
                <Typography component="h1" variant="h5">
                    Reset Password
                </Typography>
                <form className={classes.form} onSubmit={e => resetPassword(e)}>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12}>
                            <FormControl className={clsx(classes.margin, classes.textField)} variant="outlined"
                                         fullWidth>
                                <InputLabel htmlFor="password">Password</InputLabel>
                                <OutlinedInput
                                    id="password"
                                    name="password"
                                    type={displayPassword ? 'text' : 'password'}
                                    value={credentials.password}
                                    onChange={handleChange}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {displayPassword ? <Visibility/> : <VisibilityOff/>}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    labelWidth={70}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <br/>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12}>
                            <FormControl className={clsx(classes.margin, classes.textField)} variant="outlined"
                                         fullWidth>
                                <InputLabel htmlFor="confirmPassword">Confirm Password</InputLabel>
                                <OutlinedInput
                                    id="confirmPassword"
                                    name="confirmPassword"
                                    type={displayConPassword ? 'text' : 'password'}
                                    value={credentials.confirmPassword}
                                    onChange={handleChange}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowConPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {displayConPassword ? <Visibility/> : <VisibilityOff/>}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    labelWidth={130}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        type="submit"
                    >
                        Reset
                    </Button>
                </form>
            </div>
            <Box mt={8}>
                <Copyright/>
            </Box>
        </Container>
    );
};

export default ResetPasswordForm;
