import {Dashboard, InsertChart} from '@material-ui/icons';
import DashboardComponent from "../containers/Dashboard/Dashboard";
import Analyses from "../containers/Analyses/Analyses";

const dashboard =  {
    path: "/",
    name: "Dashboard",
    icon: Dashboard,
    component: DashboardComponent
};

const analytics =  {
    path: "/analytics",
    name: "Analytics",
    icon: InsertChart,
    component: Analyses
};


const redirect =  { redirect: true, path: "/", to: "/schools"};
const partnerRoutes = [dashboard,analytics];

export default partnerRoutes;
