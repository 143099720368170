import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {Paper} from "@material-ui/core";
import BackButton from "../../components/Buttons/BackButton";
import Utils from "../../utils/utils";
import VacancyService from "../../services/vacancyService";
import Button from "@material-ui/core/Button";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import ProgressBar from "../ProgressBar/ProgressBar";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));


const VacancyDetails = props => {

    const classes = useStyles();
    const [vacancy, setVacancy] = useState({
        details: {}
    });
    const [displayCloseForm, setDisplayCloseForm] = useState(false);
    const [closedVacancy, setClosedVacancy] = useState({
        student_global_id: '',
        closed_date: '',
        vacancy_id: '',
        reason: ''
    });
    const [displayLoader, setDisplayLoader] = useState(false);
    const reasons = [
        {label: "Occupied", value: "Occupied"},
        {label: "Other", value: "Other"},
    ];

    const rows = [
        {label: 'Position', key: 'position'},
        {label: 'Field of Education', key: 'field_of_education'},
        {label: 'Type', key: 'job_type'},
        {label: 'Deadline', key: 'dead_line'},
        {label: 'Region', key: 'region'},
        {label: 'Application start Date', key: 'app_start_date'},
        {label: 'Qualifications', key: 'qualification'},
        {label: 'Status', key: 'vacancy_status'}
    ];

    useEffect(() => {
        loadVacancyData();
    }, [props.match.params.vacancy_id]);

    useEffect(() => {
        setDisplayLoader(true);
    }, []);

    // Method to load the details of a vacancy
    const loadVacancyData = () => {
        VacancyService.loadVacancy(props.match.params.vacancy_id, response => {
            if (response.data) {
                let _vacancy = {...vacancy};
                let _closeData = {...closedVacancy};
                _closeData.closed_date = Utils.getToday();
                _closeData.vacancy_id = response.data.id;
                _vacancy.details = response.data;
                _vacancy.details.vacancy_status = response.data.closed_date ? "Closed" : "Open";
                setVacancy(_vacancy);
                setClosedVacancy(_closeData);
                setDisplayLoader(false);
            } else {
                Utils.displayMessage('error', 'Failed', response.errors[0]).then(r => r);
            }
        });
    };

    // Method to hide form to close a vacancy
    const hideCloseForm = () => {
        setDisplayCloseForm(false);
    };

    // Method to update values of the text fields
    const handleChange = e => {
        let val = e.target.value;
        let name = e.target.name;
        let _data = {...closedVacancy};
        _data[name] = val;
        setClosedVacancy(_data);
    };

    // Method to display a pop for closing a vacancy confirmation
    const showCloseForm = () => {
        let text = "Vacancy will be closed, do you want to continue?";
        Utils.confirmDeleteMessage(text)
            .then((willDelete) => {
                if (willDelete) {
                    setDisplayCloseForm(true);
                }
            });
    };

    const submitCloseData = () => {
        VacancyService.closeVacancy(closedVacancy, result => {
            if (result.error) {
                Utils.displayMessage('error', 'Failed', result.errors[0]).then(r => r);
            } else {
                Utils.displayMessage('success', 'Successful', result.success).then(r => r);
                loadVacancyData();
                hideCloseForm();
            }
        });
    };

    // Method to display form to close a vacancy
    const closeVacancyForm = () => {
        return (
            <Dialog open={displayCloseForm} onClose={hideCloseForm} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Close Vacancy</DialogTitle>
                <DialogContent>
                    <TextField
                        required
                        id="reason"
                        name="reason"
                        select
                        label="Reason of Closing"
                        value={closedVacancy.reason}
                        onChange={e => handleChange(e)}
                        variant="outlined"
                        style={{
                            border: 0,
                            outline: 0,
                            marginBottom: 7
                        }}
                        fullWidth
                    >
                        {reasons.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        required
                        id="student_global_id"
                        name="student_global_id"
                        label="Student Global ID"
                        variant="outlined"
                        value={closedVacancy.student_global_id}
                        onChange={e => handleChange(e)}
                        fullWidth
                        style={{marginBottom: 7}}
                        helperText="Fill in student's global ID if occupant is a current graduate."
                    />
                    <TextField
                        id="closed_date"
                        name="closed_date"
                        label="Close Date"
                        type="date"
                        value={closedVacancy.closed_date}
                        onChange={e => handleChange(e)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" onClick={hideCloseForm} variant="contained">
                        Cancel
                    </Button>
                    <Button onClick={submitCloseData} color="primary" variant="contained">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    // Method to navigate back to the previous component/view
    const goBack = () => {
        props.history.goBack();
    };

    // Method to navigate to the edit view to edit a vacancy
    const editVacancy = () => {
        props.history.push({
            pathname: `${props.match.url}/edit-vacancy`,
            vacancyData: vacancy.details
        });
    };

    return (
        <div>
            {
                displayLoader ? <div style={{marginBottom: 15}}><ProgressBar/></div> : null
            }
            <div style={{marginBottom: 10}}>
                <Button variant="contained" color="primary" onClick={editVacancy}>Edit</Button>
                <Button variant="contained" color="default" onClick={showCloseForm}>Close</Button>
                <BackButton goBack={goBack} direction={'right'} color={"secondary"} title={"Back"}/>
            </div>
            <div>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={8}>
                        <TableContainer component={Paper}>
                            <h3 style={{marginLeft: 10}}>Vacancy Details</h3>
                            <Table className={classes.table} aria-label="simple table">
                                <TableBody>
                                    {rows.map((row) => {
                                        let _data = row.key;
                                        return (
                                            <TableRow>
                                                <TableCell align="left">{row.label}</TableCell>
                                                <TableCell
                                                    align="left">{(_data === 'dead_line' || _data === 'app_start_date') ? Utils.dateFormat(vacancy.details[_data]) : vacancy.details[_data]}</TableCell>
                                            </TableRow>)
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </div>
            {closeVacancyForm()}
        </div>
    )
};

export default VacancyDetails;
