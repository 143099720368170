import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import StudentsDashboard from "./studentsDashboard";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import LcDashboard from "./lcDashboard";
import StaffDashboard from "./staffDashboard";

const styles = (theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
});

class Analyses extends React.Component {
    state = {
        dashboardId: -1,
        dashboards: [
            {label: "Learning Centers", value: 5},
            {label: "Staff", value: 6},
            {label: "Students", value: 4},
        ]
    };

    // Method to handle the updating of the value of the textfield
    handleChange = (e) => {
        this.setState({
            dashboardId: parseInt(e.target.value)
        });
    };

    render() {
        const {classes} = this.props;

        return (
            <div>
                <Card>
                    <div style={{padding: 10}}>
                        <form className={classes.root} autoComplete="on">
                            <Grid container>
                                <Grid item xs={12} sm={12} md={12}>
                                    <TextField
                                        required
                                        id="dashboardId"
                                        name="dashboardId"
                                        select
                                        label="Select Model"
                                        value={this.state.dashboardId}
                                        onChange={(e) => this.handleChange(e)}
                                        variant="outlined"
                                        style={{
                                            border: 0,
                                            outline: 0
                                        }}
                                        helperText="Select model to show analyses for."
                                        fullWidth
                                    >
                                        {this.state.dashboards.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </Card>
                {
                    this.state.dashboardId === 6 && <StaffDashboard dashboardId={6}/>
                }
                {
                    this.state.dashboardId === 4 && <StudentsDashboard dashboardId={4}/>
                }
                {
                    this.state.dashboardId === 5 && <LcDashboard dashboardId={5}/>
                }
            </div>
        );
    }
}

export default withStyles(styles)(Analyses);


