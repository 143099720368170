import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LcService from "../../services/lcService";
import TableContainer from "@material-ui/core/TableContainer";
import MyTable from "@material-ui/core/Table";
import Table from 'material-table';
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {Paper} from "@material-ui/core";
import BackButton from "../../components/Buttons/BackButton";
import ProgressBar from "../ProgressBar/ProgressBar";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

const Industry_Program_Details = props => {

    const classes = useStyles();
    const [students, setStudents] = useState([]);
    const [lcProgData, setLcProgData] = useState({
        progData: null,
        lcData: null,
    });
    const [displayLoader, setDisplayLoader] = useState(false);

    useEffect(() => {
        setDisplayLoader(true);
    }, []);

    // Method to make requests when the view/component is mounted
    // to get the list of programmes and students for a learning center
    useEffect(() => {
        LcService.loadSchoolProgram(props.match.params.id, props.match.params.prog_id, result => {
            let _data = {...lcProgData};
            _data.lcData = result.lc;
            _data.progData = result.data;
            setLcProgData(_data);
        });

        LcService.loadProgramStudents(props.match.params.id, props.match.params.prog_id, result => {
            setStudents(result.students);
            setDisplayLoader(false);
        });
    }, []);

    // Method to that returns user to the previous page or view
    const goBack = () => {
        props.history.goBack();
    };

    const prog_rows = [
        {label: 'Programme Name', key: 'name'},
        {label: 'Isced Level', key: 'isced_level'},
        {label: 'Tuition Fee', key: 'tuition'},
        {label: 'Duration', key: 'duration'},
        {label: 'Accreditation', key: 'accreditation_status'},
        {label: 'Accreditation Number', key: 'accreditation_number'}
    ];
    const lc_rows = [
        {label: 'Phone', key: 'phone'},
        {label: 'Email', key: 'email'},
        {label: 'Website', key: 'website'}
    ];

    return lcProgData.lcData && lcProgData.progData && (
        <div>
            {
                displayLoader ? <div style={{marginBottom: 15}}><ProgressBar/></div> : null
            }
            <div style={{marginBottom: 10}}>
                <BackButton goBack={goBack} direction={'right'} color={"secondary"} title={"Back"}/>
            </div>
            <div>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={4}>
                        <TableContainer component={Paper}>
                            <MyTable className={classes.table} aria-label="simple table">
                                <h3 style={{textAlign: 'center'}}>{lcProgData.lcData.name}</h3>
                                <TableBody>
                                    {prog_rows.map((row) => (
                                        <TableRow>
                                            <TableCell align="left">{row.label}</TableCell>
                                            <TableCell align="left">{lcProgData.progData[row.key]}</TableCell>
                                        </TableRow>
                                    ))}
                                    {lc_rows.map((row) => (
                                        <TableRow>
                                            <TableCell align="left">{row.label}</TableCell>
                                            <TableCell align="left">{lcProgData.lcData[row.key]}</TableCell>
                                        </TableRow>
                                    ))}

                                </TableBody>
                            </MyTable>
                        </TableContainer>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8}>
                        <Table
                            title={lcProgData.progData.name + " Students"}
                            columns={[
                                {title: 'Number of Students', field: 'total_students'},
                                {title: 'Female Students', field: 'female_count'},
                                {title: 'Male Students', field: 'male_count'},
                                {title: 'Completion Year', field: 'completion_year'},
                                {title: 'Level', field: 'level'},
                            ]}
                            data={students}
                            options={{
                                exportButton: true, filtering: false,
                                grouping: false, sorting: false,
                                debounceInterval: 1000, search: false,
                                selection: false, showTitle: true,
                                pageSize: 5, actionsColumnIndex: -1
                            }}
                        />
                    </Grid>
                </Grid>
            </div>
        </div>
    )
};

export default Industry_Program_Details;
