import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {Paper} from "@material-ui/core";
import BackButton from "../../../components/Buttons/BackButton";
import Utils from "../../../utils/utils";
import LcService from "../../../services/lcService";
import ProgressBar from "../../ProgressBar/ProgressBar";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));


const StaffDetails = props => {

    const classes = useStyles();
    const [staff, setStaff] = useState({
        details: {}
    });
    const [displayLoader, setDisplayLoader] = useState(false);

    const rows = [
        {label: 'First Name', key: 'first_name'},
        {label: 'Middle Name', key: 'middle_names'},
        {label: 'Last Name', key: 'last_name'},
        {label: 'Gender', key: 'gender'},
        {label: 'DOB', key: 'dob'},
        {label: 'Staff ID', key: 'staff_id'},
        {label: 'Nationality', key: 'nationality'},
        {label: 'Qualification', key: 'highest_qual'},
        {label: 'Employment Date', key: 'employment_date'},
        {label: 'Main Teaching Field', key: 'main_teach_field'},
        {label: 'Rank', key: 'rank'},
        {label: 'Role', key: 'role'},
        {label: 'Role', key: 'role'},
        {label: 'License', key: 'license'},
        {label: 'Certified In', key: 'certificated_in'},
    ];

    useEffect(() => {
        setDisplayLoader(true);
    }, []);

    useEffect(() => {
        LcService.loadStaff(props.match.params.schoolId, props.match.params.staffId, response => {
            if (response.data) {
                let _staff = {...staff};
                _staff.details = response.data;
                setStaff(_staff);
                setDisplayLoader(false);
            } else {
                Utils.displayMessage('error', 'Failed', response.message).then(r => r);
            }
        });

        if (props.history.location.inst_name) {
            localStorage.setItem('inst_name', props.history.location.inst_name);
        }
    }, []);

    const goBack = () => {
        props.history.goBack();
    };

    return staff.details !== undefined && (
        <div>
            {
                displayLoader ? <div style={{marginBottom: 15}}><ProgressBar/></div> : null
            }
            <div style={{marginBottom: 10}}>
                <BackButton goBack={goBack} direction={'right'} color={"secondary"} title={"Back"}/>
            </div>
            <div>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={7}>
                        <TableContainer component={Paper}>
                            <h3 style={{marginLeft: 10}}>Staff Details</h3>
                            <Table className={classes.table} aria-label="simple table">
                                <TableBody>
                                    {rows.map((row) => {
                                        let _data = row.key;
                                        return (
                                            <TableRow>
                                                <TableCell align="left">{row.label}</TableCell>
                                                <TableCell align="left">{(_data === 'employment_date' || _data === 'dob') ? Utils.dateFormat(staff.details[_data]) : staff.details[_data]}</TableCell>
                                            </TableRow>)
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
};

export default StaffDetails;
