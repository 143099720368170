import React from 'react';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {NavLink} from "react-router-dom";
import useStyles from './sidebarStyles';

const Sidebar = (props) => {
    const classes = useStyles();
    return (
        <Drawer
            variant="permanent"
            classes={{
                paper: clsx(classes.drawerPaper, !props.open && classes.drawerPaperClose),
            }}
            open={props.open}
        >
            <div className={classes.toolbarIcon}>
                <IconButton onClick={props.handleDrawerClose}>
                    <ChevronLeftIcon />
                </IconButton>
            </div>
            <Divider />
            <List>
                {
                    props.routes.map((route, key) =>{
                        const ItemIcon = route.icon;
                        return(
                            <NavLink
                                exact
                                to={route.path}
                                key={key}
                                className={classes.item}
                            >
                                <ListItem button>
                                    <ListItemIcon>
                                        <ItemIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={route.name} />
                                </ListItem>
                            </NavLink>
                        );
                    })
                }
            </List>
        </Drawer>
    );
};

export default Sidebar;
