import React from "react";
import {Link} from "react-router-dom";
import Button from "@material-ui/core/Button";

const MyButton = (props) => {
    return(
        <Link to={props.to} style={{textDecoration: 'none', float: props.direction}}>
            <Button variant="contained" color={props.color} fullWidth={props.fullWidth}>
                {props.title}
            </Button>
        </Link>
    );
};

export default MyButton;
