import React from "react";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Iframe from "react-iframe";
import Menu from "@material-ui/core/Menu/Menu";
import TextField from '@material-ui/core/TextField';
import cookies from "react-cookies";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import $ from "jquery";
import PropTypes from "prop-types";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Utils from "../../utils/utils";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import Dialog from '@material-ui/core/Dialog';

class ReportView extends React.Component {


    state = {
        periods: [
            {
                value: 'day',
                label: 'Day',
            },
            {
                value: 'week',
                label: 'Week',
            },
            {
                value: 'month',
                label: 'Month',
            },
            {
                value: 'year',
                label: 'Year',
            },

        ],
        report: {view: false, content: null, name: null, params: null, baseUrl: null, parts: null},
        pages: [],
        currentPage: null,
        openEmail: false,
        scheduleEmail: false,
        repeatPeriod: 'day',
        repeatCount: 1,
        stopAfter: 'never',
        stopDate: '',
        nbTry: '',
        stopAfterValue: '',
    };

    componentDidMount() {
        Utils.setGlobal(this);
    };

    componentDidUpdate() {
        Utils.setGlobal(this);
    };

    hideReport = () => {
        this.setState({report: {view: false, name: null, url: null}, currentPage: null});
    };

    downloadURL = (url) => {
        if ($('#idown').length) {
            $('#idown').attr('src', url);
        } else {
            $('<iframe>', {id: 'idown', src: url}).hide().appendTo('body');
        }
    };

    getReportUrl = () => {
        return Utils.getReportUrl(this.state.report.baseUrl, this.state.report.parts);
    };

    toPdf = () => {
        this.downloadURL(this.getReportUrl().replace('/1?','/1/download?') + '&format=application/pdf&name=' + this.state.report.name)
    };

    toWord = () => {
        this.downloadURL(this.getReportUrl().replace('/1?','/1/download?') + '&format=application/vnd.openxmlformats-officedocument.wordprocessingml.document&name=' + this.state.report.name)
    };

    toXsl = () => {
        this.downloadURL(this.getReportUrl().replace('/1?','/1/download?') + '&format=application/vnd.openxmlformats-officedocument.spreadsheetml.sheet&name=' + this.state.report.name)
    };

    toEmail = () => {
        this.setState({openEmail: true});
    };

    getType = (params, attr) => {
        for (var i = 0; i < params.length; i++) {
            if (params[i].name == attr && params[i].type == 'date')
                return true;
        }
        return false;
    };

    handleReportChange = (e) => {
        let params = this.state.report;

        if (this.getType(params.params, e.target.name)) {
            params.parts.params[e.target.name] = new Date(Date.parse(e.target.value));
        } else {
            params.parts.params[e.target.name] = e.target.value;
        }
        Utils.loadReport(params.baseUrl, params.parts, params.params);
    };

    loadParams = () => {

        const getDefault = (pa) => {
            if (pa.type == 'date') {
                return pa.default.toISOString().substr(0, 10);
            } else {
                return pa.default;
            }
        };

        const params = this.state.report.params;
        if (params) {
            const _this = this;
            return <div>
                {params.map(function (param) {
                    return <FormControlLabel control={<TextField
                        required
                        id={param.name}
                        name={param.name}
                        inputProps={{style: {padding: "4px", height: "1.5em"}}}
                        defaultValue={getDefault(param)}
                        onChange={(e) => {
                            _this.handleReportChange(e);
                        }}
                        margin="none"
                        type={param.type}
                        variant="outlined"
                    />} label={param.label} labelPlacement={'start'}/>

                })}
            </div>
        } else {
            return <div/>
        }
    };

    validate = (params) => {
        let errors = [];
        if (!params.to) {
            errors.push("You must specify and email address");
            return errors;
        }
        let parts = params.to.split(',');
        let valid = true;
        let invalidEmail = null;
        parts.forEach(function (item) {
            if (valid && /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/.test(item))
                valid = true;
            else {
                valid = false;
                invalidEmail = item;
            }
        });
        if (!valid) {
            errors.push("Invalid email format " + invalidEmail);
        }
        if (!params.subject) {
            errors.push("You must specify a valid subject");
        }
        if (!params.body) {
            errors.push("You must specify a detailed message")
        }
        return errors;
    };

    sendEmail = () => {

        let schedule = null;
        if (this.state.scheduleEmail) {
            let val = null;
            if (this.state.stopAfter == 'givenDate')
                val = this.state.stopDate;
            else if (this.state.stopAfter == 'numberOfTries')
                val = this.state.nbTry;

            schedule = JSON.stringify({
                count: this.state.repeatCount,
                period: this.state.repeatPeriod,
                after: {when: this.state.stopAfter, if: val}
            })
        }

        let params = {
            to: $('#emailsToSend')[0].value,
            subject: $('#emailSubject')[0].value,
            body: $('#emailBody')[0].value,
            from: 'no_reply@afrijula.gm'
        };
        let p = this.validate(params);
        if (p.length > 0) {
            alert(p[0]);
            return;
        }
        this.handleClose();
        let urls = this.getReportUrl();
        let url = urls.split('?')[1];

        let urlToSend = 'https://api.lecket.gm/reports/email/email?' + url + '&format=application/pdf' + '&email=' + JSON.stringify(params);

        if (schedule) {
            urlToSend += '&schedule=' + schedule;
        }
        $.ajax({
            url: urlToSend,
            type: 'GET',
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            dataType: 'json',
            success: function (data) {
                // displaySuccess(data.success);
            },
            error: function (err) {
                alert(err.responseText);
            }
        });

        this.setState({scheduleEmail: false});


    };

    handleClose = () => {
        this.setState({openEmail: false, scheduleEmail: false});
    };

    toPrint = () => {
        let _this = this;
        var item = $('#reportFrame').contents().find('html');
        if (item.lenth == 0) {
            alert("The report has not fully loaded");
            return;
        }
        $('#reportFrame').contents().find('.jrPage').each(function (idx, y) {
            if ($(this)[0] !== _this.state.currentPage[0]) {
                $(_this.state.pages[idx]).show();
            }
        });
        // execPrint(item[0]);
        $('#reportFrame').contents().find('.jrPage').each(function (idx) {
            if ($(this)[0] !== _this.state.currentPage[0]) {
                $(_this.state.pages[idx]).hide();
            }
        });
    };

    loadOptions = () => {
        let _this = this;
        return this.state.pages.map((page, idx) => {
            return <MenuItem value={idx} onClick={(item) => {
                _this.state.currentPage.hide();
                _this.state.currentPage = _this.state.pages[item.currentTarget.value];
                _this.state.currentPage.show();
                _this.setState({anchorEl: null});
            }}>{this.state.pageList[idx]}</MenuItem>
        });

    };

    handleStop = (e) => {
        let stop = "";
        let stopValue = '';
        switch (e.target.value) {
            case "never": {
                stop = e.target.value;
                stopValue = e.target.value;
                break;
            }
            case "givenDate": {
                stop = e.target.value;
                stopValue = this.state.stopDate;
                break;
            }
            case "numberOfTries": {
                stop = e.target.value;
                stopValue = this.state.nbTry;
                break;
            }
        }
        this.setState({
            stopAfter: stop,
            stopAfterValue: stopValue
        });
    };

    handleChange = (e) => {
        this.setState({
                [e.target.name]: e.target.value
            }
        );

    };

    handleSchedule = (e) => {
        let schedule = (e.target.value === 'yes') ? true : false;
        this.setState({
            scheduleEmail: schedule,
        });
    };

    render() {
        const {classes} = this.props;
        return (
            <div>
                <Card>
                    <Dialog fullScreen={true} open={this.state.report.view} onClose={this.hideReport.bind(this)}
                            aria-labelledby="form-dialog-title">
                        <DialogContent>
                            <div style={{position: 'relative'}}>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <Card>
                                            <Grid container>
                                                <Grid item xs={12} sm={12} md={7}>
                                                    <Button xs={12} sm={12} md={2} size={"sm"} color="primary"
                                                            onClick={this.toPdf}><i
                                                        className="material-icons">picture_as_pdf</i></Button>
                                                    <Button xs={12} sm={12} md={2} size={"sm"} color="primary"
                                                            onClick={this.toWord}>Word</Button>
                                                    <Button xs={12} sm={12} md={2} size={"sm"} color="primary"
                                                            onClick={this.toXsl}>Excel</Button>
                                                    <Button style={{float: 'right'}} xs={12} sm={12} md={2} size={"sm"}
                                                            color="info"
                                                            aria-owns={this.state.anchorEl ? 'simple-menu' : undefined}
                                                            aria-haspopup={true} onClick={event => {
                                                        this.setState({anchorEl: event.currentTarget});
                                                    }}>Pages</Button>
                                                    <Menu id="simple-menu" style={{zIndex: 200000000}}
                                                          anchorEl={this.state.anchorEl}
                                                          open={this.state.anchorEl ? true : false}
                                                          onClose={event => {
                                                              this.setState({enchorEl: null})
                                                          }}>
                                                        {this.loadOptions()}
                                                    </Menu>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={5}>
                                                    {this.loadParams()}
                                                    <Button xs={12} sm={12} md={2} size={"sm"} color="danger"
                                                            onClick={this.hideReport.bind(this)}
                                                            style={{float: 'right'}}><i
                                                        className="material-icons">close</i></Button>
                                                </Grid>
                                            </Grid>
                                        </Card>
                                    </Grid>
                                </Grid>
                                <div style={{position: 'relative', top: '10px'}}>
                                    <Iframe frameBorder={0}
                                            loading={"auto"}
                                            width={'100%'}
                                            height={'650px'}
                                            id={"reportFrame"}
                                            display={"initial"}
                                            position={"relative"}/>
                                </div>
                            </div>
                        </DialogContent>
                    </Dialog>
                </Card>
            </div>
        );
    }
}

ReportView.propTypes = {
    classes: PropTypes.object.isRequired
};

// export default withStyles(dashboardStyle)(ReportView);
export default ReportView;
