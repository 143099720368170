import React from 'react';
import TextField from '@material-ui/core/TextField';
import {withStyles} from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import {Card, Grid} from '@material-ui/core';
import Utils from "../../utils/utils";
import MenuItem from "@material-ui/core/MenuItem";
import UserService from "../../services/userService";
import IndustryService from "../../services/industryService";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import LcService from "../../services/lcService";

const styles = (theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
});

class AddSchAdmin extends React.Component {
    state = {
        school_admin: {
            first_name: '',
            middle_names: '',
            last_name: '',
            email: '',
            password: '',
            password_confirmation: '',
            org: '',
            role: '',
            school_admin: true
        },
        roles: [],
        classification: 'Higher Education',
        schools: [],
        higher: [],
        tertiary: [],
        non_tertiary: []
    };

    handleChangeRadBtn = (event) => {
        let temp = [];
        if(event.target.value === 'Tertiary'){
            temp = [...this.state.tertiary]
        }
        else if(event.target.value === 'Non-Tertiary'){
            temp = [...this.state.non_tertiary]
        }else{
            temp = [...this.state.higher]
        }

        this.setState({schools: temp, classification: event.target.value});
    };

    loadData = () => {
        LcService.loadSchools('Higher Education', response => {
            let temp_1 = [];
            response.data.forEach(sch => temp_1.push({label: sch.name, value: sch.id}));
            this.setState({higher: temp_1, schools: temp_1});
        });

        LcService.loadSchools('Tertiary', response => {
            let temp_2 = [];
            response.data.forEach(sch => temp_2.push({label: sch.name, value: sch.id}));
            this.setState({tertiary: temp_2});
        });

        LcService.loadSchools('Non Tertiary', response => {
            let temp_3 = [];
            response.data.forEach(sch => temp_3.push({label: sch.name, value: sch.id}));
            this.setState({non_tertiary: temp_3, displayLoader: false});
        });
    };

    // Method to update values of the text fields
    handleChange = e => {
        let val = e.target.value;
        let school_admin = {...this.state.school_admin};
        school_admin[e.target.name] = val;
        this.setState({
            school_admin: school_admin
        });
    };

    // Method to clear values of the text fields
    clearForm = () => {
        let school_admin = this.state.school_admin;
        school_admin.first_name = '';
        school_admin.middle_names = '';
        school_admin.last_name = '';
        school_admin.email = '';
        school_admin.password = '';
        school_admin.password_confirmation = '';
        school_admin.org = '';
        school_admin.role = '';
        this.setState({
            school_admin: school_admin
        });
    };

    // Method to validate values of the text fields
    validateFields = () => {
        const {
            first_name, last_name, password,
            password_confirmation, org, role, email
        } = this.state.school_admin;

        if (first_name === '' || !Utils.validateName(first_name)) return 'first_name';
        if (last_name === '' || !Utils.validateName(last_name)) return 'last_name';
        if (password === '' && !Utils.validateText(password)) return 'password';
        if (password_confirmation === '' && !Utils.validateText(password_confirmation)) return 'password_confirmation';
        if (org === '' && !Utils.validateText(org)) return 'org';
        if (role === '' && !Utils.validateText(role)) return 'role';
        if (email === '' && !Utils.validateEmail(email)) return 'email';
    };

    /*
    * Method to set focus on the text fields with invalid data
    * Parameters:
    * + field_id: The id of the field to set the focus on
    * */
    setFocus = (field_id) => {
        Utils.displayMessage('error', 'Error', 'Please specify ' + field_id).then(r => r);
        document.getElementById(field_id).focus();
    };

    // Method to send and save data to the server
    saveData = () => {
        let data = this.state.school_admin;

        let result = this.validateFields();

        if (result) {
            this.setFocus(result);
            return;
        }
        UserService.create(data, response => {
            let msg = localStorage.getItem('userId') ? "School admin Details updated" : "School admin successfully added";
            if (response.data) {
                Utils.displayMessage('success', 'Successful', msg).then(r => r);
                this.clearForm();
                this.goBack();
            }
        });
    };

    // Initializes the select field values of the form
    initializeSelectFields = () => {
        UserService.getRoles(response => {
            if (response.data) {
                this.setState({
                    roles: response.data.filter(role => role.name === 'school-admin')
                })
            }
        });

        IndustryService.loadStakeholders(response => {
            if (response.data) {
                this.setState({
                    orgs: response.data
                })
            }
        });
    };

    /*
    * Method to populate the text field values when editing a school_admin
    * Parameters:
    * + data: The data of the school_admin to be edited
    * */
    populateForm = data => {
        let _data = {...data}
        let role_id = this.getRoleId(_data.role);
        _data.role = {id: role_id, name: data.role, created_at: new Date(), updated_at: new Date()};
        _data.role = data.role_id;
        this.setState({
            school_admin: _data
        });
    };

    // Method to navigate back to the previous component/view
    goBack = () => {
        this.props.history.goBack();
    };

    /*
    * Method to get the data of a specific role
    * Parameters:
    * + roleId: The id of the school_admin role to get data for
    * */
    getRoleId = (roleId) => {
        let n = this.state.roles.length;
        let role = "";
        if (n > 0) {
            for (let i = 0; i < n; i++) {
                if (this.state.roles[i].name === roleId) {
                    role = this.state.roles[i];
                    break;
                }
            }
            return role;
        }
    };

    componentDidMount() {
        this.initializeSelectFields();
        this.loadData();
        if (this.props.location.userData) {
            let _data = {...this.props.location.userData};
            localStorage.setItem('userId', _data.id);

            this.populateForm(_data);
        } else if (this.props.location.userData === undefined && localStorage.getItem('userId') !== null) {
            UserService.getUser(localStorage.getItem('userId'), this.populateForm)
        }
    };

    render() {
        const {classes} = this.props;
        let school_admin = this.state.school_admin;

        return (
            <div>
                <Card>
                    <div style={{marginLeft: 150}}>
                        <form className={classes.root} autoComplete="on">
                            <h2>{localStorage.getItem('userId') !== null ? "Edit School Admin" : "Add School Admin"}</h2>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={12}>
                                    <TextField
                                        required
                                        id="first_name"
                                        name="first_name"
                                        label="First Name"
                                        variant="outlined"
                                        value={school_admin.first_name}
                                        onChange={e => this.handleChange(e)}
                                    />
                                    <TextField
                                        id="middle_names"
                                        name="middle_names"
                                        label="Middle Name"
                                        variant="outlined"
                                        value={school_admin.middle_names}
                                        onChange={e => this.handleChange(e)}
                                    />
                                    <TextField
                                        required
                                        id="last_name"
                                        name="last_name"
                                        label="Last Name"
                                        variant="outlined"
                                        value={school_admin.last_name}
                                        onChange={e => this.handleChange(e)}
                                    />
                                    <TextField
                                        required
                                        id="email"
                                        name="email"
                                        label="Email"
                                        variant="outlined"
                                        type="email"
                                        value={school_admin.email}
                                        onChange={e => this.handleChange(e)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <TextField
                                        required
                                        id="role"
                                        name="role"
                                        label="Role"
                                        variant="outlined"
                                        value={school_admin.role}
                                        onChange={e => this.handleChange(e)}
                                        select
                                    >
                                        {
                                            this.state.roles.map((option) => (
                                                <MenuItem key={option.id} value={option.id}>
                                                    {option.name}
                                                </MenuItem>
                                            ))
                                        }
                                    </TextField>
                                    {
                                        localStorage.getItem('userId') !== null ?
                                            null
                                            :
                                            <>
                                                <TextField
                                                    required
                                                    id="password"
                                                    name="password"
                                                    label="Password"
                                                    variant="outlined"
                                                    type="password"
                                                    value={school_admin.password}
                                                    onChange={e => this.handleChange(e)}
                                                />
                                                <TextField
                                                    required
                                                    id="password_confirmation"
                                                    name="password_confirmation"
                                                    label="Password Confirmation"
                                                    variant="outlined"
                                                    type="password"
                                                    value={school_admin.password_confirmation}
                                                    onChange={e => this.handleChange(e)}
                                                />
                                            </>
                                    }
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} style={{marginTop: 10}}>
                                    <FormLabel component="legend">School Classification</FormLabel>
                                    <RadioGroup aria-label="classification" name="classification" value={this.state.classification} onChange={e => this.handleChangeRadBtn(e)} row>
                                        <FormControlLabel value="Higher Education" control={<Radio />} label="Higher Education" />
                                        <FormControlLabel value="Tertiary" control={<Radio />} label="Tertiary" />
                                        <FormControlLabel value="Non-Tertiary" control={<Radio />} label="Non Tertiary" />
                                    </RadioGroup>
                                    <TextField
                                        required
                                        id="org"
                                        name="org"
                                        label="Institution"
                                        variant="outlined"
                                        value={school_admin.org}
                                        onChange={e => this.handleChange(e)}
                                        select
                                        style={{width: '75%'}}
                                    >
                                        {
                                            this.state.schools.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))
                                        }
                                    </TextField>
                                </Grid>
                            </Grid>

                        </form>
                    </div>
                    <div style={{padding: 5}}>
                        <Button variant="contained" color="secondary" onClick={this.goBack} style={{margin: 5}}>
                            Cancel
                        </Button>
                        <Button variant="contained" color="primary" onClick={this.saveData} style={{margin: 5}}>
                            Save
                        </Button>
                    </div>
                </Card>
            </div>
        );
    }
}

export default withStyles(styles)(AddSchAdmin);


