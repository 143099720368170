import React,{Component} from 'react';
import SimpleTabs from "../../components/Tab/Tab";
import SimpleView from "../SimpleView/SimpleView";
import LcService from "../../services/lcService";
import ProgressBar from "../ProgressBar/ProgressBar";

class Institution_Industry extends Component{

    state= {
        higherEd: [],
        tertiary: [],
        nonTertiary: [],
        displayLoader: false
    };

    // Method to load all the  different schools
    loadData = () => {
        LcService.loadSchools('Higher Education', response => {
            this.setState({higherEd: response.data});
        });

        LcService.loadSchools('Tertiary', response => {
            this.setState({tertiary: response.data});
        });

        LcService.loadSchools('Non Tertiary', response => {
            this.setState({nonTertiary: response.data, displayLoader: false});
        });
    };

    componentDidMount() {
        this.setState({
            displayLoader: true
        });
        this.loadData();
    }

    render(){

        const tabs = [

            {
                name: 'Higher Education',
                index: 0,
                component: SimpleView,
                props: {
                    name: 'Higher Education Institutions',
                    addButton: 'New Higher Education',
                    buttonPath: '/new-institution',
                    headers: [{ title: 'Name', field: 'name' },
                        { title: 'Ownership', field: 'ownership' },
                        { title: 'Region', field: 'region' },
                        { title: 'LGA', field: 'lga' }
                    ],
                    data: this.state.higherEd
                }
            },
            {
                name: 'Tertiary',
                index: 1,
                component: SimpleView,
                props: {
                    name: 'Tertiary Institutions',
                    addButton: 'New Tertiary',
                    buttonPath: '/new-institution',
                    headers: [{ title: 'Name', field: 'name' },
                        { title: 'Ownership', field: 'ownership' },
                        { title: 'Region', field: 'region' },
                        { title: 'LGA', field: 'lga' }
                    ],
                    data: this.state.tertiary
                }
            },
            {
                name: 'Non Tertiary',
                index: 2,
                component: SimpleView,
                props: {
                    name: 'Non Tertiary Institutions',
                    addButton: 'New Non Tertiary',
                    buttonPath: '/new-institution',
                    headers: [{ title: 'Name', field: 'name' },
                        { title: 'Ownership', field: 'ownership' },
                        { title: 'Region', field: 'region' },
                        { title: 'LGA', field: 'lga' }
                    ],
                    data: this.state.nonTertiary
                }
            }
        ];
        return(
            <>
                {
                    this.state.displayLoader ? <div style={{marginBottom: 15}}><ProgressBar/></div> : null
                }
                <SimpleTabs tabs={tabs}/>
            </>
        );
    }
}

export default Institution_Industry;
