import React, {Component} from 'react';
import SimpleView from "../SimpleView/SimpleView";
import Utils from "../../utils/utils";
import VacancyService from "../../services/vacancyService";
import cookies from "react-cookies";
import ProgressBar from "../ProgressBar/ProgressBar";

class Vacancy extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            displayLoader: false
        }
    };

    // Update state table data with the received data from the server
    setUpData = (result) => {
        if (result.data) {
            this.setState({
                data: result.data,
                displayLoader: false
            });
        } else {
            Utils.displayMessage('error', 'Failed', result.errors[0]).then(r => r);
        }
    };

    // Method to send request to load vacancies of an organization
    loadData = () => {
        VacancyService.loadVacancies(cookies.load('orgId'), this.setUpData);
    };

    componentDidMount() {
        this.setState({
            displayLoader: true
        });
        this.loadData();
        localStorage.removeItem('vacancyData');
    }

    render() {
        const data = {
            name: "Vacancy List",
            addButton: 'New Vacancy',
            buttonPath: '/new-vacancy',
            headers: [
                {title: 'Position', field: 'position'},
                {
                    title: 'Application Deadline',
                    field: 'dead_line',
                    render: rowData => Utils.dateFormat(rowData.dead_line)
                },
                {title: 'Field of Education', field: 'field_of_education'},
                {title: 'Type', field: 'job_type'},
                {
                    title: 'Status',
                    field: 'isFilled',
                    render: rowData => rowData.closed_date !== null ? "Closed" : "Open"
                }
            ],
            data: this.state.data,
            onlyBackButton: false,
            noRowClick: false
        };
        return (
            <>
                {
                    this.state.displayLoader ? <ProgressBar/> : null
                }
                <div style={{marginTop: 10}}>
                    <SimpleView data={data} showAddButton={true} refreshTable={this.loadData} model="Vacancy" noFiltering={true} noGrouping={true} showDeleteButton={true}/>
                </div>
            </>
        );
    }
}

export default Vacancy;
