import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {Paper} from "@material-ui/core";
import MyButton from "../../components/Buttons/Button";
import IndustryService from "../../services/industryService";
import BackButton from "../../components/Buttons/BackButton";
import TableHead from "@material-ui/core/TableHead";
import VacancyService from "../../services/vacancyService";
import ProgressBar from "../ProgressBar/ProgressBar";
import cookies from "react-cookies";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));


const IndustryDetails = props => {

    const classes = useStyles();
    const [stakeholder, setStakeholder] = useState({});
    const [rows, setRows] = useState([]);
    const [vacancies, setVacancies] = useState([]);
    const [displayLoader, setDisplayLoader] = useState(false);

    // Method to initialize table data fields with received data from the server
    const setUpRowData = result => {
        let temp = [];
        temp.push({label: 'Name', key: result.name});
        temp.push({label: 'Address', key: result.address});
        temp.push({label: 'Email', key: result.email});
        temp.push({label: 'Sector', key: result.sector});
        temp.push({label: 'Website', key: result.website});
        temp.push({label: 'Staff Count', key: result.staff_count});
        temp.push({label: 'Region', key: result.region});
        temp.push({label: 'Field', key: result.field});
        temp.push({label: 'Offers Internship', key: (result.intership) === 1 ? 'Yes' : 'No'});

        setRows(temp);
        setStakeholder(result);
        setDisplayLoader(false);
    };

    useEffect(() => {
        setDisplayLoader(true);
    }, []);

    useEffect(() => {
        IndustryService.loadStakeholder(props.match.params.id, setUpRowData);
        VacancyService.loadVacancies(props.match.params.id, response => {
            setVacancies(response.data);
        });
    }, [props.match.params.id]);

    const goBack = () => {
        props.history.goBack();
    };

    const _match = props.match;

    return rows.length > 0 && (
        <div>

            <h2>Industry Details</h2>
            {
                displayLoader ? <div style={{marginBottom: 15}}><ProgressBar/></div> : null
            }
            <div style={{marginBottom: 10}}>
                {
                    cookies.load('user_type') === 'analyst' ?
                        null :
                        <MyButton to={{pathname: `${_match.url}/edit`, stakeholderData: stakeholder, editing: true}}
                                  direction={'left'} color={"primary"}
                                  title={"Edit"}
                        />
                }

                <BackButton goBack={goBack} direction={'right'} color={"secondary"} title={"Back"} />
            </div>
            <div>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={5}>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} aria-label="simple table">
                                <TableBody>
                                    {rows.map((row) => (
                                        <TableRow>
                                            <TableCell align="left">{row.label}</TableCell>
                                            <TableCell align="left">{row.key}</TableCell>
                                        </TableRow>
                                    ))}

                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item xs={12} sm={12} md={7}>
                        <TableContainer component={Paper}>
                            <h3 style={{marginLeft: 10}}>Vacancy List</h3>
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Position</TableCell>
                                        <TableCell>Field of Education</TableCell>
                                        <TableCell>Application Deadline</TableCell>
                                        <TableCell>Type</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        vacancies.map(vc =>(
                                            <TableRow>
                                                <TableCell align="left">{vc.position}</TableCell>
                                                <TableCell align="left">{vc.field_of_education}</TableCell>
                                                <TableCell align="left">{vc.dead_line}</TableCell>
                                                <TableCell align="left">{vc.job_type}</TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
};

export default IndustryDetails;
