import { ADD_STUDENT } from "../actionTypes/studentActionTypes";

export const addStudent = content => ({
    type: ADD_STUDENT,
    payload: {content}

});


// export const setFilter = filter => ({ type: SET_FILTER, payload: { filter } });
