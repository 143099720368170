import React, {Component} from 'react';
import Table from 'material-table';
import MyButton from "../../components/Buttons/Button";
import {Route, withRouter} from "react-router-dom";
import BackButton from "../../components/Buttons/BackButton";
import Button from "@material-ui/core/Button";
import Utils from "../../utils/utils";
import VacancyService from "../../services/vacancyService";


class SimpleView extends Component {
    constructor(props) {
        super(props);
        this.tableRef = React.createRef();

        this.state = {
            header: this.props.data.headers,
            students: []
        };
    };

    // Method to navigate to a view to view details pf the clicked row
    rowSelect = (event, rowData) => {
        this.props.history.push(`${this.props.match.url}/${rowData.id}`);
    };

    // Method to refresh the data of a table
    refreshTable = () => {
        this.tableRef.current && this.tableRef.current.onQueryChange();
    };

    // Method to navigate back to the previous view/component
    goBack = () => {
        this.props.history.goBack();
    };

    // Method to send a delete request of the corresponding data.
    deleteData = (event, rowData) => {
        let text = `${this.props.model} will be deleted, do you want to continue?`;
        Utils.confirmDeleteMessage(text)
            .then((willDelete) => {
                if (willDelete) {
                    VacancyService.deleteVacancy(rowData.id, result => {
                        if (result.error) {
                            Utils.displayMessage('error', 'Failed', result.errors[0]).then(r => r);
                        } else {
                            Utils.displayMessage('success', 'Successful', result.success).then(r => r);
                            this.props.refreshTable();
                        }
                    });
                }
            });
    };

    render() {
        return (

            <div>
                <div style={{marginBottom: 10}}>
                    {
                        (this.props.data.onlyBackButton === true) ?
                            <div style={{float: 'right', marginTop: -40}}>
                                <BackButton goBack={this.goBack} direction={'right'} color={"secondary"}
                                            title={"Back"}/>
                            </div>
                            :
                            <div>
                                {
                                    (this.props.showAddButton) ? <MyButton to={this.props.data.buttonPath} color={"primary"} title={this.props.data.addButton} />: null
                                }
                                {
                                    (this.props.showAddSchAdminButton) ? <MyButton to={this.props.data.buttonPathSchAdmin} color={"default"} title={this.props.data.addSchAdminButton} />: null
                                }
                            </div>
                    }
                </div>
                <div>
                    <Table
                        tableRef={this.state.tableRef}
                        title={this.props.data.name}
                        columns={this.state.header}
                        data={this.props.data.data}
                        onRowClick={(this.props.data.noRowClick) ? '' : this.rowSelect}
                        options={{
                            exportButton: true, filtering: !this.props.noFiltering,
                            grouping: !this.props.noGrouping, sorting: true,
                            debounceInterval: 1000,
                            selection:  false, showTitle: true,
                            pageSize: 5, actionsColumnIndex: -1
                        }}
                        localization={{
                            header: {
                                actions: ""
                            }
                        }}
                        actions={[
                            {
                                icon: 'refresh', tooltip: 'Refresh', isFreeAction: true,
                                onClick: () => this.refreshTable(),
                            },
                            (this.props.showDeleteButton) ?
                                rowData => ({
                                    icon: () => (
                                        <Button
                                            color="secondary"
                                            variant="contained"
                                            style={{textTransform: 'none'}}
                                            size="small"
                                        >
                                            DELETE
                                        </Button>
                                    ),
                                    tooltip: `Delete ${this.props.model}`,
                                    onClick: (event, rowData) => this.deleteData(event, rowData),
                                }) : null
                        ]}
                    />
                </div>
            </div>
        );
    }
}

export default withRouter(SimpleView);


