import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import {Grid} from '@material-ui/core';
import clsx from "clsx";
import OutlinedInput from "@material-ui/core/OutlinedInput/OutlinedInput";
import IconButton from "@material-ui/core/IconButton";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import InputAdornment from "@material-ui/core/InputAdornment";
import Utils from "../../utils/utils";
import * as cookies from "react-cookies";
import UserService from "../../services/userService";

const styles = (theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
});

class ResetPassword extends React.Component {
    state = {
        credentials: {
            password: '',
            password_confirmation: ''
        },
        showPassword: false,
        showConfirmPassword: false,
    };

    // Method to update values of the text fields
    handleChange = e => {
        let val = e.target.value.trim();
        let _credentials = {...this.state.credentials};
        if (e.target.name === 'phone_number' && Utils.isValid(val)) {
            Utils.displayMessage("warning", "", "Only numbers allowed").then(r => r);
            return;
        }
        _credentials[e.target.name] = val;
        this.setState({
            credentials: _credentials
        });
    };

    // Method to show/hide password text field values
    handleClickShowPassword = () => {
        this.setState(prevState => ({
            showPassword: !prevState.showPassword
        }));
    };

    // Method to show/hide confirm password text field values
    handleClickShowConfirmPassword = () => {
        this.setState(prevState => ({
            showConfirmPassword: !prevState.showConfirmPassword
        }));
    };

    handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    // Method to navigate back to the previous component/view
    goBack = () => {
        this.props.history.goBack();
    };

    // Method to clear form fields data
    clearForm = () => {
        let _credentials = {...this.state.credentials};

        _credentials.password = '';
        _credentials.password_confirmation = '';
        this.setState({
            credentials: _credentials
        });
    };

    // Method to submit request to reset password
    resetPassword = () => {
        let data = {...this.state.credentials};
        if (data.password !== data.password_confirmation) {
            Utils.displayMessage('error', 'Error', 'Password mismatch!').then(r => r);
            document.getElementById('password').focus();
        } else {
            UserService.changePassword(data, result => {
                if (result.error) {
                    Utils.displayMessage('error', 'Error', result.message).then(r => r);
                } else {
                    Utils.displayMessage('success', 'Successful', result.message).then(r => r);
                    this.clearForm();
                }
            });
        }
    };

    // Method to load details of the current logged in user
    loadUser = () => {
        UserService.getUser(cookies.load('currentUserId'), result => {
            this.setState({credentials: {...result}} );
        });
    };

    componentDidMount() {
        this.loadUser();
    };

    render() {
        const {classes} = this.props;
        let _credentials = this.state.credentials;

        return (
            <div>
                <div style={{marginLeft: 150}}>
                    <form className={classes.root} autoComplete="on">
                        <h4>Reset Password</h4>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={12}>
                                <FormControl className={clsx(classes.margin, classes.textField)} required
                                             style={{marginRight: 7, marginTop: 8}}
                                             variant="outlined">
                                    <InputLabel htmlFor="outlined-adornment-password">New Password</InputLabel>
                                    <OutlinedInput
                                        id="password"
                                        type={this.state.showPassword ? 'text' : 'password'}
                                        value={_credentials.password}
                                        name="password"
                                        onChange={e => this.handleChange(e)}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={this.handleClickShowPassword}
                                                    onMouseDown={e => this.handleMouseDownPassword(e)}
                                                    edge="end"
                                                >
                                                    {this.state.showPassword ? <Visibility/> : <VisibilityOff/>}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        labelWidth={70}
                                    />
                                </FormControl>
                                <FormControl className={clsx(classes.margin, classes.textField)} required
                                             style={{marginTop: 8}}
                                             variant="outlined">
                                    <InputLabel htmlFor="outlined-adornment-password">Confirm
                                        Password</InputLabel>
                                    <OutlinedInput
                                        id="password_confirmation"
                                        type={this.state.showConfirmPassword ? 'text' : 'password'}
                                        value={_credentials.password_confirmation}
                                        name="password_confirmation"
                                        onChange={e => this.handleChange(e)}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={this.handleClickShowConfirmPassword}
                                                    onMouseDown={e => this.handleMouseDownPassword(e)}
                                                    edge="end"
                                                >
                                                    {this.state.showConfirmPassword ? <Visibility/> :
                                                        <VisibilityOff/>}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        labelWidth={70}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </form>
                </div>
                <div style={{padding: 5}}>
                    <Button variant="contained" color="primary" onClick={this.resetPassword} style={{margin: 5}}>
                        Reset
                    </Button>
                </div>
            </div>
        )
    }
}

export default withStyles(styles)(ResetPassword);
