import {Dashboard, InsertChart, School} from '@material-ui/icons';
import DashboardStakeHolder from "../containers/Dashboard/StakeHolderDashboard";
import WorkIcon from '@material-ui/icons/Work';
import Vacancy from "../containers/Vacancy/Vacancy";
import AnalysesIndustry from "../containers/Vacancy/AnalysesIndustry";
import Institution_Industry from "../containers/Industry/Institution_Industry";

const dashboard = {
    path: "/",
    name: "Dashboard",
    icon: Dashboard,
    component: DashboardStakeHolder
};

const analytics = {
    path: "/analytics",
    name: "Analytics",
    icon: InsertChart,
    component: AnalysesIndustry
};

const institution_industry = {
    path: "/schools",
    name: "Institutions",
    icon: School,
    component: Institution_Industry
};

const vacancy = {
    path: "/vacancy",
    name: "Vacancy",
    icon: WorkIcon,
    component: Vacancy
};

const industryRoutes = [dashboard, analytics, institution_industry, vacancy];

export default industryRoutes;
