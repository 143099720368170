import React from 'react';
import TextField from '@material-ui/core/TextField';
import {withStyles} from '@material-ui/core/styles';
import {Card} from '@material-ui/core';
import Utils from '../../utils/utils';
import {connect} from "react-redux";
import {addStudent} from "../../redux/actions/studentActions";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";

const styles = (theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
});

class Organization extends React.Component {
    state = {
        orgData: {},
    };

    // Method to update the values of the text fields
    handleChange = e => {
        e.preventDefault();
        this.props.updateData(e.target.name, e.target.value);
    };

    goBack = () => {
        this.props.history.goBack();
    };

    componentDidMount() {
        this.setState({orgData: this.props.orgData})
    }

    render() {
        const {classes} = this.props;
        let org = this.props.orgData;

        return (
            <div>
                <Card>
                    <div style={{textAlign: "center"}}>
                        <form className={classes.root} autoComplete="on">
                            <h5>Enter Organization Information</h5>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={12}>
                                    <TextField
                                        required
                                        id="name"
                                        name="name"
                                        label="Name"
                                        variant="outlined"
                                        value={org.name}
                                        onChange={e => this.handleChange(e)}
                                    />
                                    <TextField
                                        id="email"
                                        name="email"
                                        label="Email"
                                        variant="outlined"
                                        value={org.email}
                                        onChange={e => this.handleChange(e)}
                                    />
                                    <TextField
                                        required
                                        id="address"
                                        name="address"
                                        label="Address"
                                        variant="outlined"
                                        value={org.address}
                                        onChange={e => this.handleChange(e)}
                                    />
                                    <TextField
                                        id="website"
                                        name="website"
                                        label="Website"
                                        variant="outlined"
                                        value={org.website}
                                        onChange={e => this.handleChange(e)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <TextField
                                        id="staff_count"
                                        name="staff_count"
                                        label="Number of Staff"
                                        variant="outlined"
                                        value={org.staff_count}
                                        onChange={e => this.handleChange(e)}
                                    />
                                    <TextField
                                        required
                                        id="sector"
                                        name="sector"
                                        select
                                        label="Sector"
                                        value={org.sector}
                                        onChange={(e) => this.handleChange(e)}
                                        variant="outlined"
                                        style={{
                                            border: 0,
                                            outline: 0
                                        }}
                                    >
                                        {Utils.sector.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <TextField
                                        required
                                        id="region"
                                        name="region"
                                        select
                                        label="Region"
                                        value={org.region}
                                        onChange={(e) => this.handleChange(e)}
                                        variant="outlined"
                                        style={{
                                            border: 0,
                                            outline: 0
                                        }}
                                    >
                                        {Utils.lga.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <TextField
                                        id="field"
                                        name="field"
                                        select
                                        label="Field"
                                        variant="outlined"
                                        value={org.field}
                                        onChange={e => this.handleChange(e)}
                                        style={{
                                            border: 0,
                                            outline: 0
                                        }}
                                    >
                                        {Utils.fieldsOfEducation.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <TextField
                                        id="internship"
                                        name="intership"
                                        select
                                        label="Offer Internships"
                                        variant="outlined"
                                        value={org.intership}
                                        onChange={e => this.handleChange(e)}
                                        style={{
                                            border: 0,
                                            outline: 0
                                        }}
                                    >
                                        <MenuItem key='yes' value='yes'>Yes</MenuItem>
                                        <MenuItem key='no' value='no'>No</MenuItem>
                                    </TextField>
                                    <TextField
                                        required
                                        id="org_type"
                                        name="org_type"
                                        select
                                        label="Type"
                                        variant="outlined"
                                        value={org.org_type}
                                        onChange={e => this.handleChange(e)}
                                        style={{
                                            border: 0,
                                            outline: 0
                                        }}
                                    >
                                        {
                                            Utils.orgTypes.map(option => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))
                                        }
                                    </TextField>

                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </Card>
            </div>
        );
    }
}

export default connect(null, {addStudent})(withStyles(styles)(Organization));


