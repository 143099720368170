import $ from "jquery";
import Utils from "../utils/utils";

import {Api} from "../config/api_config";
import cookies from 'react-cookies';

export default class VacancyService {

    /*
    * Method to create a vacancy
    * Parameters:
    * + data: the values of the new vacancy to be created
    * + callback: method to process response from the server
    * */
    static createVacancy = (data, callback) =>{
        let _method = (data.id) ? "PUT" : "POST";
        let id = (data.id) ? data.id : '';
        $.ajax({
            method: _method,
            url: Api.DEV_BASE_URL+"/v1/vacancy/"+id,
            dataType: "json",
            headers: {
                "Authorization": "Bearer " + cookies.load("token")
            },
            data: data,
            success: function(result) {
                callback(result);
            },
            error: function(e){
                Utils.displayMessage('error', 'Failed', 'Vacancy could not saved').then(r => r);
            }
        });
    };

    /*
    * Method to load the educations fields for vacancy select fields
    * Parameters:
    * + callback: method to process response from the server
    * */
    static loadEducationFields = (callback) =>{
        $.ajax({
            method: "GET",
            url: Api.DEV_BASE_URL+"/v1/vacancy/load_education_fields",
            dataType: "json",
            headers: {
                "Authorization": "Bearer " + cookies.load("token")
            },
            success: function(result) {
                callback(result);
            },
            error: function(e){
                Utils.displayMessage('error','Failed', 'Fields of education could not be loaded')
            }
        });
    };

    /*
    * Method to load the list of vacancies of an organization
    * Parameters:
    * + id: the id of the organization to load vacancies for
    * + callback: method to process response from the server
    * */
    static loadVacancies = (id, callback) =>{
        $.ajax({
            method: "GET",
            url: Api.DEV_BASE_URL+"/v1/vacancy",
            dataType: "json",
            headers: {
                "Authorization": "Bearer " + cookies.load("token")
            },
            data: {org_id: id},
            success: function(result) {
                callback(result);
            },
            error: function(e){
                Utils.displayMessage('error', 'Failed', 'Vacancies could not be loaded').then(r => r)
            }
        });
    };

    /*
    * Method to load the details of a vacancy
    * Parameters:
    * + id: the id of the vacancy to be loaded
    * + callback: method to process response from the server
    * */
    static loadVacancy = (id, callback) =>{
        $.ajax({
            method: "GET",
            url: Api.DEV_BASE_URL+"/v1/vacancy/"+id,
            dataType: "json",
            headers: {
                "Authorization": "Bearer " + cookies.load("token")
            },
            success: function(result) {
                callback(result);
            },
            error: function(e){
                Utils.displayMessage('error', 'Failed', 'Vacancy could not be loaded').then(r => r)
            }
        });
    };

    /*
    * Method to close a vacancy
    * Parameters:
    * + data: the data of the vacancy to be closed
    * + callback: method to process response from the server
    * */
    static closeVacancy = (data, callback) =>{
        $.ajax({
            method: "PUT",
            url: Api.DEV_BASE_URL+"/v1/vacancy/close_vacancy",
            dataType: "json",
            headers: {
                "Authorization": "Bearer " + cookies.load("token")
            },
            data: data,
            success: function(result) {
                callback(result);
            },
            error: function(e){
                Utils.displayMessage('error', 'Failed', 'Vacancy could not be close').then(r => r)
            }
        });
    };

    /*
    * Method to create a vacancy
    * Parameters:
    * + id: the id of the vacancy to be deleted
    * + callback: method to process response from the server
    * */
    static deleteVacancy = (id, callback) =>{
        $.ajax({
            method: "DELETE",
            url: Api.DEV_BASE_URL+"/v1/vacancy/"+id,
            dataType: "json",
            headers: {
                "Authorization": "Bearer " + cookies.load("token")
            },
            success: function(result) {
                callback(result);
            },
            error: function(e){
                Utils.displayMessage('error', 'Failed', 'Vacancy could not be delete').then(r => r)
            }
        });
    }
}
