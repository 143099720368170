import React, {Component} from 'react';
import SimpleView from "../../SimpleView/SimpleView";
import Utils from "../../../utils/utils";
import lcService from "../../../services/lcService";
import ProgressBar from "../../ProgressBar/ProgressBar";

class Program extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            displayLoader: false
        }
    };

    // Update state table data with the received data from the server
    setUpData = (result) => {
        if (result.data) {
            this.setState({
                data: result.data,
                displayLoader: false
            });
        } else {
            Utils.displayMessage('error', 'Failed', result.message).then(r => r);
        }
    };

    componentDidMount() {
        this.setState({
            displayLoader: true
        });
        lcService.loadAllStaff(this.props.match.params.id, this.setUpData);
        if (this.props.history.location.inst_name) {
            localStorage.setItem('inst_name', this.props.history.location.inst_name);
        }
    }

    render() {
        const _instName = this.props.history.location.inst_name;
        const _suffix = " Staff List";
        const data = {
            name: (_instName === undefined) ? localStorage.getItem('inst_name') + _suffix : this.props.history.location.inst_name + _suffix,
            headers: [
                {title: 'First Name', field: 'first_name'},
                {title: 'Middle Name', field: 'middle_names'},
                {title: 'Last Name', field: 'last_name'},
                {title: 'Gender', field: 'gender'},
                {title: 'Nationality', field: 'nationality'},
                {title: 'Qualification', field: 'highest_qual'},
                {title: 'Employment Date', field: 'employment_date', render: rowData => Utils.dateFormat(rowData.employment_date)},
                {title: 'Employment Type', field: 'employment_type'},
                {title: 'Rank', field: 'rank'},
                {title: 'Role', field: 'role'}
            ],
            data: this.state.data,
            onlyBackButton: true
        };
        return (
            <>
                {
                    this.state.displayLoader ? <ProgressBar/> : null
                }
                <div style={{marginTop: 55}}>
                    <SimpleView data={data}/>
                </div>
            </>
        );
    }
}

export default Program;
