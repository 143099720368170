import { ADD_STUDENT } from "../actionTypes/studentActionTypes";

const initialState = {
    students: [],
    student: {}
};

export default function(state = initialState, action) {
    switch (action.type) {
        case ADD_STUDENT: {
            const { content } = action.payload;
            return {
                ...state,
                students: [...state.students,content]
            };
        }
        default:
            return state;
    }
}
