import React, {useState} from "react";
import {ExcelRenderer} from "react-excel-renderer";
import {Button, Upload} from "antd"
import MatButton from '@material-ui/core/Button';
import Utils from "../../utils/utils";
import ProgressBar from "../../components/ProgressBar/ProgressBar";
import Card from "@material-ui/core/Card";
import "antd/dist/antd.css";
import PublishIcon from '@material-ui/icons/Publish';
import Grid from "@material-ui/core/Grid";
import LcService from "../../services/lcService";

const ImportComponent = ({title}) => {
    const [data, setData] = useState([]);
    const [showProgress, setShowProgress] = useState(false);
    const [showButton, setShowButton] = useState(false);

    const checkValidCols = (cols, component) => {
        let _valid = false;
        switch (component) {
            case 'Institution':
                _valid = cols.length === 19;
                return _valid;
            case 'Staff':
                _valid = cols.length === 22;
                return _valid;
            case 'Students':
                _valid = cols.length === 26;
                return _valid;
            case 'Programme':
                _valid = cols.length === 10;
                return _valid;
            case 'Curricula':
                _valid = cols.length === 15;
                return _valid;
            case 'Scholarships':
                _valid = cols.length === 11;
                return _valid;
            case 'Infrastructure':
                _valid = cols.length === 11;
                return _valid;
            case 'Laboratory':
                _valid = cols.length === 6;
                return _valid;
            default:
                return _valid;
        }
    };

    const formatDate = (serial) => {
        let utc_days = Math.floor(serial - 25569);
        let utc_value = utc_days * 86400;
        let date_info = new Date(utc_value * 1000);

        let _month = date_info.getMonth() + 1;
        return date_info.getDate() + "-" + _month + "-" + date_info.getFullYear();
    };

    const fileHandler = fileList => {
        let fileObj = fileList;
        if (!fileObj) {
            Utils.displayMessage("error", "Failed", "No file uploaded!").then(r => r);
        }
        if (
            !(
                fileObj.type === "application/vnd.ms-excel" ||
                fileObj.type ===
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            )
        ) {
            Utils.displayMessage("error", "Failed", "Unknown file format. Only Excel files are uploaded!").then(r => r);
        }
        //just pass the fileObj as parameter
        ExcelRenderer(fileObj, (err, resp) => {
            if (err) {
                Utils.displayMessage("error", "Failed", err).then(r => r);
            } else {
                let newRows = [];
                let _col = resp.rows[0];
                let _isValid = checkValidCols(_col, title);
                if (!_isValid) {
                    Utils.displayMessage("error", "Error", "Invalid number of columns, please use the exact template provided by MoHERST.").then(r => r);
                } else {
                    let _rows = resp.rows.slice(1);
                    for (let i = 0; i < _rows.length; i++) {
                        let temp = _rows[i];
                        let obj = {};
                        for (let j = 0; j < _col.length; j++) {
                            let _key = _col[j].trim();
                            if (_key.indexOf(' ') >= 0) {
                                _key = _key.replace(/ /g, "_");
                            }
                            let index = _key.indexOf('(');
                            if (index !== -1) {
                                _key = _key.substring(0, index - 1);
                            }
                            if (_key === "NAT'L_NAME") {
                                _key = "NATL_PROGRAMME";
                            }
                            let _val = null;
                            _val = (/\s/.test(temp[j])) ? temp[j].replace(/\s\s+/g, ' ') : temp[j];
                            if ((_key === 'DOB' || _key === 'EMP_DATE' || _key === 'EXPECTED_COMP_YR' || _key === 'ADM_YR')) {
                                if((typeof _val === "string") && (_val.split(" ")[0].indexOf("th") > -1 || _val.split(" ")[0].indexOf("st") > -1)){
                                    Utils.displayMessage("error", "Error", `Invalid Date format ${_val}, please see the date format on the template!`).then(r => r);
                                    return;
                                }
                                obj[_key] = formatDate(_val);
                            }
                            if(_key === 'CLASSIFICATION' && (_val === 'Non-Tertiary' || _val === 'non-tertiary')){
                                obj[_key] = _val.replace(/-/g, " ");
                            }
                            else {
                                obj[_key] = _val;
                            }
                        }
                        newRows.push(obj);
                    }
                    if (newRows.length === 0) {
                        Utils.displayMessage("error", "Failed", "No data found in file!").then(r => r);
                        return false
                    } else {
                        setData(newRows);
                        setShowProgress(false);
                        setShowButton(true);
                    }
                }
            }
        });
        return false
    };

    const removeData = () => {
        setData([]);
        setShowButton(false);
        setShowProgress(false);
    };

    const submitStaffData = () => {
        LcService.importStaff(data, (result) => {
            if (result.error) {
                setShowProgress(false);
                Utils.displayMessage('error', 'Failed', result.errors[0]).then(r => r);
            } else {
                setShowProgress(false);
                removeData();
                Utils.displayMessage('success', 'Successful', result.success).then(r => r);
            }
        });
    };

    const submitInstitutionData = () => {
        LcService.importInstitution(data, (result) => {
            if (result.error) {
                setShowProgress(false);
                Utils.displayMessage('error', 'Failed', result.errors[0]).then(r => r);
            } else {
                setShowProgress(false);
                removeData();
                Utils.displayMessage('success', 'Successful', result.success).then(r => r);
            }
        });
    };

    const splitUp = (arr, n) => {
        let rest = arr.length % n,
            restUsed = rest,
            partLength = Math.floor(arr.length / n),
            result = [];

        for (let i = 0; i < arr.length; i += partLength) {
            let end = partLength + i,
                add = false;

            if (rest !== 0 && restUsed) {
                end++;
                restUsed--;
                add = true;
            }

            result.push(arr.slice(i, end));

            if (add) {
                i++;
            }
        }

        return result;
    };


    const submitStudentsData = () => {
        let _temp = data.length < 20 ? splitUp(data, 5) : splitUp(data, 20);
        LcService.importStudents(_temp, (result) => {
            if (result.error) {
                setShowProgress(false);
                Utils.displayMessage('error', 'Failed', result.errors[0]).then(r => r);
            } else {
                setShowProgress(false);
                removeData();
                Utils.displayMessage('success', 'Successful', result.success).then(r => r);
            }
        });
    };

    const submitProgramsData = () => {
        LcService.importProgrammes(data, (result) => {
            if (result.error) {
                setShowProgress(false);
                Utils.displayMessage('error', 'Failed', result.errors[0]).then(r => r);
            } else {
                setShowProgress(false);
                removeData();
                Utils.displayMessage('success', 'Successful', result.success).then(r => r);
            }
        });
    };

    const submitCurriculaData = () => {
        LcService.importCurricula(data, (result) => {
            if (result.error) {
                setShowProgress(false);
                Utils.displayMessage('error', 'Failed', result.errors[0]).then(r => r);
            } else {
                setShowProgress(false);
                removeData();
                Utils.displayMessage('success', 'Successful', result.success).then(r => r);
            }
        });
    };

    const submitInfrastructureData = () => {
        LcService.importInfrastructure(data, (result) => {
            if (result.error) {
                setShowProgress(false);
                Utils.displayMessage('error', 'Failed', result.errors[0]).then(r => r);
            } else {
                setShowProgress(false);
                removeData();
                Utils.displayMessage('success', 'Successful', result.success).then(r => r);
            }
        });
    };

    const submitScholarshipsData = () => {
        LcService.importScholarships(data, (result) => {
            if (result.error) {
                setShowProgress(false);
                Utils.displayMessage('error', 'Failed', result.errors[0]).then(r => r);
            } else {
                setShowProgress(false);
                removeData();
                Utils.displayMessage('success', 'Successful', result.success).then(r => r);
            }
        });
    };

    const submitSchoolLabData = () => {
        LcService.importSchoolLab(data, (result) => {
            if (result.error) {
                setShowProgress(false);
                Utils.displayMessage('error', 'Failed', result.errors[0]).then(r => r);
            } else {
                setShowProgress(false);
                removeData();
                Utils.displayMessage('success', 'Successful', result.success).then(r => r);
            }
        });
    };

    const submitData = (title) => {
        setShowProgress(true);
        if (title === 'Institution') {
            submitInstitutionData();
        }
        if (title === 'Staff') {
            submitStaffData();
        }
        if (title === 'Students') {
            submitStudentsData();
        }
        if (title === 'Programme') {
            submitProgramsData();
        }
        if (title === 'Curricula') {
            submitCurriculaData();
        }
        if (title === 'Infrastructure') {
            submitInfrastructureData();
        }
        if (title === 'Scholarships') {
            submitScholarshipsData();
        }
        if (title === 'Laboratory') {
            submitSchoolLabData();
        }
    };

    return (

        <Grid item xs={12} sm={6}>
            <Card style={{textAlign: "center", padding: 50}}>
                {
                    showProgress ? <ProgressBar/> : null
                }
                <br/>
                <h2>{`Import ${title} excel sheet`}</h2>
                <div>
                    <Upload
                        name="file"
                        beforeUpload={fileHandler}
                        onRemove={removeData}
                        multiple={false}
                    >
                        <Button>
                            <PublishIcon style={{marginRight: 10}}/>
                            Click to Upload Excel File
                        </Button>
                    </Upload>
                </div>
                {
                    showButton ?
                        <MatButton onClick={() => submitData(title)} variant="contained" color="primary"
                                   style={{marginTop: 15}}>
                            Save
                        </MatButton> : null
                }
            </Card>
        </Grid>
    );
};

export default ImportComponent;
