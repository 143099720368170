import {ImportExport} from '@material-ui/icons';
import AttachmentIcon from '@material-ui/icons/Attachment';
import ImportComponent from "../components/ImportComponent/ImportComponent";
import Templates from "../components/ImportComponent/Templates";

const template = {
    path: "/",
    name: "Download Templates",
    icon: AttachmentIcon,
    component: Templates

};

const import_data = {
    path: "/import-data",
    name: "Import Data",
    icon: ImportExport,
    component: ImportComponent

};

const schoolAdminRoutes = [template, import_data];

export default schoolAdminRoutes;
