import React from 'react';
import TextField from '@material-ui/core/TextField';
import {withStyles} from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import MyButton from "../../components/Buttons/Button";
import {Card, Grid} from '@material-ui/core';
import Utils from "../../utils/utils";
import MenuItem from "@material-ui/core/MenuItem";
import LcService from "../../services/lcService";

const styles = (theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
});

class AddInstitution extends React.Component {
    state = {
        newLc: {
            name: '',
            address: '',
            website: '',
            lga: '',
            ownership: '',
            financial_source: '',
            classification: '',
            phone: '',
            enrolment_capacity: '',
            email: '',
            lecture_room_capacity: '',
            lecture_room_count: ''
        },
        funding_types: [
            {label: "Scholarship", value: "Scholarship"},
            {label: "Self funding", value: "Self funding"}
        ],
    };

    // Method to update the values of the textfields
    handleChange = e => {
        let val = e.target.value;
        let lc = {...this.state.newLc};
        if (e.target.name === 'phone_number' && Utils.isValid(val)) {
            Utils.displayMessage("warning", "", "Only numbers allowed").then(r => r);
            return;
        }
        lc[e.target.name] = val;
        this.setState({
            newLc: lc
        });
    };

    // Method to clear the values of the textfields
    clearForm = () => {
        let lc = this.state.newLc;
        lc.name = '';
        lc.website = '';
        lc.address = '';
        lc.ownership = '';
        lc.lga = '';
        lc.financial_source = '';
        lc.classification = '';
        lc.phone = '';
        lc.enrolment_capacity = '';
        lc.email = '';
        lc.lecture_room_capacity = '';
        lc.lecture_room_count = '';
        this.setState({
            newLc: lc
        });
    };

    saveData = () => {
        let data = this.state.newLc;
        LcService.create(data, (result) => {
            if (result.error) {
                Utils.displayMessage('error', 'Failed', result.message).then(r => r);
            } else {
                Utils.displayMessage('success', 'Successful', result.message).then(r => r);
                this.clearForm();
            }
        });
    };

    // Method to initialize select fields of the form
    initialiseSelectFields = () => {
        let _student = {...this.state.newLc};
        _student.classification = Utils.classification[0].value;
        _student.ownership = Utils.ownership[0].value;
        _student.lga = Utils.lga[0].value;
        _student.financial_source = Utils.financial_source[0].value;
        this.setState({
            newLc: _student
        })
    };

    componentDidMount() {
        if (this.props.location.studentData) {
            this.setState({
                newLc: {...this.props.location.studentData}
            })
        }
        this.initialiseSelectFields();
    }

    render() {
        const {classes} = this.props;
        const _match = this.props.match;
        let lc = this.state.newLc;
        const back_to = (this.props.location.studentData) ? Utils.getReturnUrl(_match.url) : '/institutions';

        return (
            <div>
                <Card>
                    <div style={{marginLeft: 150}}>
                        <form className={classes.root} autoComplete="on">
                            <h2>Add Institution</h2>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={12}>
                                    <TextField
                                        required
                                        id="name"
                                        name="name"
                                        label="Learning Center Name"
                                        variant="outlined"
                                        value={lc.name}
                                        onChange={e => this.handleChange(e)}
                                    />
                                    <TextField
                                        required
                                        id="classification"
                                        name="classification"
                                        label="classification"
                                        variant="outlined"
                                        select
                                        value={lc.classification}
                                        onChange={e => this.handleChange(e)}
                                        style={{
                                            border: 0,
                                            outline: 0
                                        }}
                                    >
                                        {Utils.classification.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <TextField
                                        id="ownership"
                                        name="ownership"
                                        label="Ownership"
                                        variant="outlined"
                                        select
                                        value={lc.ownership}
                                        onChange={e => this.handleChange(e)}
                                        style={{
                                            border: 0,
                                            outline: 0
                                        }}
                                    >
                                        {Utils.ownership.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <TextField
                                        required
                                        id="address"
                                        name="address"
                                        label="Address"
                                        variant="outlined"
                                        value={lc.address}
                                        onChange={e => this.handleChange(e)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={12}>
                                    <TextField
                                        required
                                        id="lga"
                                        name="lga"
                                        select
                                        label="LGA"
                                        value={lc.lga}
                                        onChange={(e) => this.handleChange(e)}
                                        variant="outlined"
                                        style={{
                                            border: 0,
                                            outline: 0
                                        }}
                                    >
                                        {Utils.lga.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <TextField
                                        required
                                        id="email"
                                        name="email"
                                        label="Email"
                                        variant="outlined"
                                        value={lc.email}
                                        onChange={e => this.handleChange(e)}
                                    />
                                    <TextField
                                        required
                                        id="financial_source"
                                        name="financial_source"
                                        select
                                        label="Financial Source"
                                        value={lc.financial_source}
                                        onChange={(e) => this.handleChange(e)}
                                        variant="outlined"
                                        style={{
                                            border: 0,
                                            outline: 0
                                        }}
                                    >
                                        {Utils.financial_source.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <TextField
                                        id="website"
                                        name="website"
                                        label="Website"
                                        variant="outlined"
                                        value={lc.website}
                                        onChange={e => this.handleChange(e)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={12}>
                                    <TextField
                                        id="phone"
                                        name="phone"
                                        label="Phone"
                                        variant="outlined"
                                        value={lc.phone}
                                        onChange={e => this.handleChange(e)}
                                    />
                                    <TextField
                                        id="enrolment_capacity"
                                        name="enrolment_capacity"
                                        label="Enrolment Capacity"
                                        type="enrolment_capacity"
                                        value={lc.enrolment_capacity}
                                        onChange={e => this.handleChange(e)}
                                        variant="outlined"
                                    />
                                    <TextField
                                        id="lecture_room_capacity"
                                        name="lecture_room_capacity"
                                        label="Lecture Rooms Capacity"
                                        variant="outlined"
                                        value={lc.lecture_room_capacity}
                                        onChange={e => this.handleChange(e)}
                                    />
                                    <TextField
                                        id="lecture_room_count"
                                        name="lecture_room_count"
                                        label="Number of Lecture Rooms"
                                        variant="outlined"
                                        value={lc.lecture_room_count}
                                        onChange={e => this.handleChange(e)}
                                    />
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                    <div style={{padding: 5}}>
                        <MyButton to={back_to} color={"secondary"} title={"Cancel"} style={{margin: 5}}/>
                        <Button variant="contained" color="primary" onClick={this.saveData} style={{margin: 5}}>
                            Save
                        </Button>
                    </div>
                </Card>
            </div>
        );
    }
}

export default withStyles(styles)(AddInstitution);

