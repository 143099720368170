import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {Paper} from "@material-ui/core";
import MyButton from "../../components/Buttons/Button";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import UserService from "../../services/userService";
import BackButton from "../../components/Buttons/BackButton";
import TextField from "@material-ui/core/TextField";
import Rodal from "rodal";
import 'rodal/lib/rodal.css';
import Utils from "../../utils/utils";
import ProgressBar from "../ProgressBar/ProgressBar";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

const UserDetails = props => {

    const classes = useStyles();
    const [rows, setRows] = useState([]);
    const [visible, setVisibility] = useState(false);
    const [user, setUser] = useState({
        details: {}
    });
    const [userData, setUserData] = useState({
        password: '',
        password_confirmation: '',
        id: ''
    });
    const [displayLoader, setDisplayLoader] = useState(false);

    const setUpRowData = result => {
        let temp = [];
        temp.push({label: 'First Name', key: result.first_name});
        temp.push({label: 'Middle Name', key: result.middle_names});
        temp.push({label: 'Last Name', key: result.last_name});
        temp.push({label: 'Email', key: result.email});
        temp.push({label: 'Organization', key: result.org});
        temp.push({label: 'Role', key: result.role});
        result.status === 0 ? temp.push({label: 'Status', key: "Active"}) : temp.push({label: 'Status', key: "Locked"});

        setRows(temp);
    };

    // Method show Rodal
    const show = () =>{
        setVisibility(true);
    };

    // Method hide Rodal
    const hide = () =>{
        setVisibility(false);
    };

    useEffect(() => {
        setDisplayLoader(true);
    }, []);

    useEffect(() => {
        loadUserData();
    }, [props.match.params.id]);

    useEffect(() => {
        localStorage.removeItem('userId');
    },[]);

    // Method to load user details
    const loadUserData = () => {
        UserService.getUser(props.match.params.id, result => {

            let _user = {...user};
            _user.details = result;
            setUser(_user);
            setUpRowData(result);
            let _userData = {...userData};
            _userData.id =  props.match.params.id;
            setUserData(_userData);
            setDisplayLoader(false);
        });
    };

    // Method to update values of password text fields
    const handleChangePassword = (e) => {
        let _user = {...userData};
        _user[e.target.name] =  e.target.value.trim();
        setUserData(_user);
    };

    // Method to submit password details for updating
    const submitPassword = () => {
        let _userData = {...userData};
        if(_userData.password !== _userData.password_confirmation){
            Utils.displayMessage('error', 'Password mismatch', 'Passwords do not match!').then(r => r);
        }else{
            UserService.changePassword(userData, response => {
                if (response.error){
                    Utils.displayMessage('error', 'Failed', response.message).then(r => r);
                } else{
                    Utils.displayMessage('success', 'Successful', "User's password successfully cheanged").then(r => r);
                    hide();
                }
            });
        }
    };

    const goBack = () => {
        props.history.goBack();
    };

    // Method lock or unlock a user
    const changeStatus = () => {
        let _type = user.details.status === 0 ? "locked" : "unlocked";
        let text = `User account will be ${_type}, do you want to continue?`;
        Utils.confirmDeleteMessage(text)
            .then((willDelete) => {
                if (willDelete) {
                    UserService.changeStatus(userData,'change_status', response => {
                        if (response.error){
                            Utils.displayMessage('error', 'Failed', response.message).then(r => r);
                        } else{
                            let responseMsg = response.data.status === 0 ? "unlocked" : "locked";
                            Utils.displayMessage('success', 'Successful', `User's account successfully ${responseMsg}`).then(r => r);
                            loadUserData();
                        }
                    });
                }
            });
    };

    return (
        <div>
            <div><h2>User Details</h2></div>
            {
                displayLoader ? <div style={{marginBottom: 15}}><ProgressBar/></div> : null
            }
            <div style={{marginBottom: 10}}>
                <MyButton to={{pathname: `${props.match.url}/edit`, userData: user.details, editing: true}}
                          direction={'left'} color={"primary"}
                          title={"Edit"}
                />
                <BackButton goBack={goBack} direction={'right'} color={"secondary"} title={"Back"}/>
            </div>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={8}>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableBody>
                                {rows.map((row) => (
                                    <TableRow>
                                        <TableCell align="left">{row.label}</TableCell>
                                        <TableCell align="left">{row.key}</TableCell>
                                    </TableRow>
                                ))}

                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                    <Card style={{textAlign: 'center', padding: 15}}>
                        <Grid item xs={12} sm={12} md={12}>
                            <Button
                                variant="contained"
                                direction={'center'}
                                color={"secondary"}
                                title={"Change Password"}
                                fullWidth={true}
                                onClick={show}
                            >Change Password</Button>
                        </Grid>
                        <br/>
                        <Grid item xs={12} sm={12} md={12}>
                            <Button
                                variant="contained"
                                direction={'center'}
                                color={"primary"}
                                title="Lock"
                                fullWidth={true}
                                onClick={changeStatus}
                            >{user.details.status === 1 ? 'Unlock' : 'Lock'}</Button>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
            <Rodal visible={visible} onClose={hide}>
                <div style={{textAlign: 'center'}}>
                    <h3>Change Password</h3>
                    <Grid item xs={12} sm={12} md={12}>
                        <TextField
                            required
                            id="password"
                            name="password"
                            label="New Password"
                            variant="outlined"
                            type="password"
                            value={userData.password}
                            onChange={handleChangePassword}
                        />
                    </Grid>
                    <br/>
                    <Grid item xs={12} sm={12} md={12}>
                        <TextField
                            required
                            id="password_confirmation"
                            name="password_confirmation"
                            label="Password Confirmation"
                            variant="outlined"
                            type="password"
                            value={userData.password_confirmation}
                            onChange={handleChangePassword}
                        />
                    </Grid>
                    <div style={{marginTop: 2}}>
                        <Button variant="contained" color="primary" onClick={submitPassword}>
                            Submit
                        </Button>
                    </div>
                </div>
            </Rodal>
        </div>
    )
};

export default UserDetails;
