import React from "react";
import Button from "@material-ui/core/Button";

const BackButton = (props) => {
    return(
        <Button
                onClick={props.goBack}
                variant="contained" color={props.color}
                style={{textDecoration: 'none', float: props.direction}}
        >
            {props.title}
        </Button>
    );
};

export default BackButton;
