import React from "react";
import Button from '@material-ui/core/Button';import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { makeStyles } from '@material-ui/core/styles';
import {Link} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(1),
    },
}));

const DownloadTemplate = ({title, to}) => {
    const classes = useStyles();

    return (
        <Grid item xs={12} sm={6}>
            <Card style={{textAlign: "center", padding: 50}}>
                <h2>{`Download ${title} template`}</h2>
                <div>
                    <Button
                        variant="contained"
                        color="default"
                        className={classes.button}
                        startIcon={<CloudDownloadIcon/>}
                    >
                        <Link to={to} target="_blank" download style={{color:'black'}}>Download</Link>
                    </Button>
                </div>
            </Card>
        </Grid>
    );
};

export default DownloadTemplate;
