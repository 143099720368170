import React, {useEffect, useState} from 'react';
import IconTile from "../../components/Tiles/IconTile";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LcService from "../../services/lcService";
import TableContainer from "@material-ui/core/TableContainer";
import MyTable from "@material-ui/core/Table";
import Table from 'material-table';
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {Paper} from "@material-ui/core";
import BackButton from "../../components/Buttons/BackButton";
import Button from "@material-ui/core/Button";
import PeopleIcon from '@material-ui/icons/People';
import SchoolIcon from '@material-ui/icons/School';
import ApartmentIcon from '@material-ui/icons/Apartment';
import BusinessIcon from '@material-ui/icons/Business';
import ProgressBar from "../ProgressBar/ProgressBar";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

const Institution_Industry_Details = props => {

    const classes = useStyles();
    const [schoolRec, setSchoolRec] = useState({
        details: {},
        labs: []
    });
    const [displayLoader, setDisplayLoader] = useState(false);
    const [tabData, setTableData] = useState({
        curriculaData: [],
        monitoringData: [],
        infrastructureData: [],
    });

    useEffect(() => {
        setDisplayLoader(true);
    }, []);

    useEffect(() => {
        LcService.loadSchool(props.match.params.id, result => {
            let _school = {...schoolRec};
            _school.details = result.data;
            _school.details.expected_graduates = result.expected_graduates;
            _school.details.total_admissions = result.total_admissions;
            _school.labs = result.school_labs;
            localStorage.setItem('inst_name', _school.details.name);
            setArrayData( result.data);
            setSchoolRec(_school);
            setDisplayLoader(false);
        });
    }, []);

    // Method to initialize table data fields
    const setArrayData = (data) => {
        let _curriculaData = [];
        let _monitoringData = [];
        let _infrastructureData = [];

        _curriculaData.push(
            {
                num_inst_qlty_pols: data.num_inst_qlty_pols,
                num_curr_developed: data.num_curr_developed,
                num_curr_accr: data.num_curr_accr,
                num_curr_reviewed: data.num_curr_reviewed,
                num_func_qlty_ass_units: data.num_func_qlty_ass_units,
                time_ration_theory_pract: data.time_ration_theory_pract
            }
        );
        _monitoringData.push(
            {
                qualification_of_teaching_staff: data.qualification_of_teaching_staff,
                num_quality_audit_visits: data.num_quality_audit_visits,
                num_annual_sup_visits: data.num_annual_sup_visits
            }
        );

        _infrastructureData.push(
            {
                num_computers: data.num_computers,stud_comp_ratio: data.stud_comp_ratio,
                num_libraries: data.num_libraries,
                lib_capacity: data.lib_capacity,
                avail_audio_visual_aids: data.avail_audio_visual_aids,
                num_male_toilets: data.num_male_toilets,
                male_stud_toilet_ratio: data.male_stud_toilet_ratio,
                num_female_toilets: data.num_female_toilets,
                female_stud_toilet_ratio: data.female_stud_toilet_ratio
            }
        );
        let tabData = {
            curriculaData: _curriculaData,
            monitoringData: _monitoringData,
            infrastructureData: _infrastructureData,
        };
        setTableData(tabData);
    };

    const goBack = () => {
        props.history.goBack();
    };

    const rows = [
        {label: 'Name', key: 'name'},
        {label: 'Address', key: 'address'},
        {label: 'Classification', key: 'classification'},
        {label: 'Local Govt Area', key: 'lga'},
        {label: 'Ownership', key: 'ownership'},
        {label: 'Financial Source', key: 'financial_source'},
        {label: 'Phone', key: 'phone'},
        {label: 'Email', key: 'email'},
        {label: 'Website', key: 'website'}
    ];

    const _url = props.match.url;

    const displayOtherView = (view) => {
        props.history.push({
            pathname: `${_url}/` + view,
            inst_name: localStorage.getItem('inst_name')
        });
    };

    return (
        <div>
            {
                displayLoader ? <div style={{marginBottom: 15}}><ProgressBar/></div> : null
            }
            <div style={{marginBottom: 10}}>
                <Button variant="contained"
                        color="primary"
                        onClick={() => displayOtherView('programs')}
                >
                    PROGRAMMES
                </Button>
                <BackButton goBack={goBack} direction={'right'} color={"secondary"} title={"Back"}/>
            </div>
            <div className={classes.root}>
                <Grid container spacing={3}>
                    <Grid item xs={3}>
                        <IconTile icon={PeopleIcon} title='Admissions' count={schoolRec.details.total_admissions}/>
                    </Grid>
                    <Grid item xs={3}>
                        <IconTile icon={ApartmentIcon} title='Lecture Rooms' count={schoolRec.details.lecture_room_count}/>
                    </Grid>
                    <Grid item xs={3}>
                        <IconTile icon={PeopleIcon} title='Enrollment Capacity' count={schoolRec.details.enrolment_capacity}/>
                    </Grid>
                    <Grid item xs={3}>
                        <IconTile icon={SchoolIcon} title='Expected Graduants' count={schoolRec.details.expected_graduates}/>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={3}>
                        <IconTile icon={BusinessIcon} title='Laboratories' count={schoolRec.labs.length}/>
                    </Grid>
                </Grid>
            </div>
            <div>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={4}>
                        <TableContainer component={Paper}>
                            <MyTable className={classes.table} aria-label="simple table">
                                <h3 style={{textAlign: 'center'}}>School Details</h3>
                                <TableBody>
                                    {rows.map((row) => (
                                        <TableRow>
                                            <TableCell align="left">{row.label}</TableCell>
                                            <TableCell align="left">{schoolRec.details[row.key]}</TableCell>
                                        </TableRow>
                                    ))}

                                </TableBody>
                            </MyTable>
                        </TableContainer>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8}>
                        <Table
                            title={schoolRec.details.name+" Infrastructure Info."}
                            columns={[
                                {title: 'Num of Computers', field: 'num_computers'},
                                {title: 'Students/Comps Rat.', field: 'stud_comp_ratio'},
                                {title: 'Num of Libraries', field: 'num_libraries'},
                                {title: 'Library Capacity', field: 'lib_capacity'},
                                {title: 'Aud/Visual Aids.', field: 'avail_audio_visual_aids'},
                                {title: 'Male Toilets', field: 'num_male_toilets'},
                                {title: 'Male/Sts Toilet Rat.', field: 'male_stud_toilet_ratio'},
                                {title: 'Fem. Toilets', field: 'num_female_toilets'},
                                {title: 'Fem/Sts. Toilet Rat.', field: 'female_stud_toilet_ratio'}
                            ]}
                            data={tabData.infrastructureData}
                            options={{
                                exportButton: true, filtering: false,
                                grouping: false, sorting: false,
                                debounceInterval: 1000,search: false,
                                selection: false, showTitle: true,
                                pageSize: 2, actionsColumnIndex: -1
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Table
                            title={schoolRec.details.name+" Curricula Info."}
                            columns={[
                                {title: 'Num Qlty Ass. Pols', field: 'num_inst_qlty_pols'},
                                {title: 'Num of Ann. Curri. dev.', field: 'num_curr_developed'},
                                {title: 'Num of Curri. accr.', field: 'num_curr_accr'},
                                {title: 'Num of Curri.Rev.', field: 'num_curr_reviewed'},
                                {title: 'Num of Qlty Ass. Units', field: 'num_func_qlty_ass_units'},
                                {title: 'Theory/Practicals Rat', field: 'time_ration_theory_pract'},
                            ]}
                            data={tabData.curriculaData}
                            options={{
                                exportButton: true, filtering: false,
                                grouping: false, sorting: false,
                                debounceInterval: 1000,search: false,
                                selection: false, showTitle: true,
                                pageSize: 2, actionsColumnIndex: -1
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Table
                            title={schoolRec.details.name+" Laboratories"}
                            columns={[
                                {title: 'Name', field: 'name'},
                                {title: 'Department', field: 'department_name'},
                                {title: 'Status', field: 'status'},
                            ]}
                            data={schoolRec.labs}
                            options={{
                                exportButton: true, filtering: false,
                                grouping: false, sorting: false,
                                debounceInterval: 1000,search: false,
                                selection: false, showTitle: true,
                                pageSize: 5, actionsColumnIndex: -1
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Table
                            title={schoolRec.details.name+" Monitoring And Supervision Info."}
                            columns={[
                                // {title: 'Qualification of Lecturers/Teaching Staff', field: 'qualification_of_teaching_staff'},
                                {title: 'Qual. of Teaching Staff', field: 'qualification_of_teaching_staff'},
                                // {title: 'Num of Ann. Quality Audit Visits', field: 'num_quality_audit_visits'},
                                {title: 'Num of Ann. Audit Visits', field: 'num_quality_audit_visits'},
                                // {title: 'Num of Annual Supervision Visits', field: 'num_annual_sup_visits'},
                                {title: 'Num of Sup Visits', field: 'num_annual_sup_visits'},
                            ]}
                            data={tabData.monitoringData}
                            options={{
                                exportButton: true, filtering: false,
                                grouping: false, sorting: false,
                                debounceInterval: 1000,search: false,
                                selection: false, showTitle: true,
                                pageSize: 2, actionsColumnIndex: -1
                            }}
                        />
                    </Grid>
                </Grid>
            </div>
        </div>
    )
};

export default Institution_Industry_Details;
