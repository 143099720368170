import React from "react";
import Grid from "@material-ui/core/Grid";
import DownloadTemplate from "./DownloadTemplate";

const Templates = () => {

    return (
        <Grid container spacing={3}>
            <DownloadTemplate title="Institution" to="/Institution_Template.xlsx" />
            <DownloadTemplate title="Staff" to="/Staff_Template.xlsx" />
            <DownloadTemplate title="Students" to="/Student_Template.xlsx" />
            <DownloadTemplate title="Programme" to="/Programmes_Template.xlsx" />
            <DownloadTemplate title="Curricula" to="/Curricula_Template.xlsx" />
            <DownloadTemplate title="Infrastructure" to="/Infrastructure_Template.xlsx" />
            <DownloadTemplate title="Scholarships" to="/Scholarships_Template.xlsx" />
            <DownloadTemplate title="Laboratory" to="/Laboratory_Template.xlsx" />
        </Grid>
    );
};

export default Templates;
