import $ from "jquery";
import Utils from "../utils/utils";
import cookies from 'react-cookies';

import {Api} from "../config/api_config";

export default class UserService {

    /*
    * Method to login a user
    * Parameters:
    * + payload: The credentials of the user to be authenticated
    * + callback: The method to handle the response coming from the server
    * */
    static login(payload, callback) {
        $.ajax({
            method: 'POST',
            url: Api.DEV_BASE_URL + "/v1/users/login/",
            data: payload,
            success: function (result) {
                callback(result);
            },
            error: function (e) {
                Utils.displayMessage('error', e).then(r => r);
            }
        })
    };

    /*
    * Method to get the details of a specific user
    * Parameters:
    * + id: The id of the field to set the focus on
    * + callback: The method to handle the response coming from the server
    * */
    static getUser(id, callback) {
        $.ajax({
            method: 'GET',
            url: Api.DEV_BASE_URL + "/v1/users/" + id,
            headers: {
                "Authorization": "Bearer " + cookies.load("token")
            },
            success: function (result) {
                if(result.data){
                    callback(result.data);
                }
            },
            error: function (e) {
                Utils.displayMessage('error', 'Error loading user').then(r => r);
            }
        })
    };

    /*
    * Method to get the list of all users
    * Parameters:
    * + callback: The method to handle the response coming from the server
    * */
    static getUsers(callback) {
        $.ajax({
            method: 'GET',
            url: Api.DEV_BASE_URL + "/v1/users/",
            headers: {
                "Authorization": "Bearer " + cookies.load("token")
            },
            success: function (result) {
                callback(result);
            },
            error: function (e) {
                Utils.displayMessage('error', 'Error loading user records').then(r => r);
            }
        })
    };

    /*
    * Method to create/edit a user
    * Parameters:
    * + data: The data of the new user to be added/edited
    * + callback: The method to handle the response coming from the server
    * method is POST if creating
    * method is PUT if editing
    * */
    static create(data, callback) {
        const id = (data.id) ? data.id : '';
        const _method = (id) ? "PUT" : "POST";

        $.ajax({
            method: _method,
            url: Api.DEV_BASE_URL + "/v1/users/" + id,
            headers: {
                "Authorization": "Bearer " + cookies.load("token")
            },
            dataType: "json",
            data: (id) ? {data: data, method: 'update_data'} : data,
            success: function (result) {
                callback(result);
            },
            error: function (e) {
                Utils.displayMessage('error', 'Failed', 'User could not be saved').then(r => r);
            }
        })
    }

    /*
    * Method to change the password of a user
    * Parameters:
    * + data: The data of the new user to be edited
    * + callback: The method to handle the response coming from the server
    * */
    static changePassword(data, callback) {
        const id = data.id;
        $.ajax({
            method: "PUT",
            url: Api.DEV_BASE_URL + "/v1/users/" + id,
            headers: {
                "Authorization": "Bearer " + cookies.load("token")
            },
            dataType: "json",
            data: {data: data, method: 'change_pwd'},
            success: function (result) {
                callback(result);
            },
            error: function (e) {
                Utils.displayMessage('error', 'Failed', 'User password could not be updated').then(r => r);
            }
        })
    }

    /*
    * Method to get the different user roles
    * Parameters:
    * + callback: The method to handle the response coming from the server
    * */
    static getRoles(callback) {
        $.ajax({
            method: 'GET',
            url: Api.DEV_BASE_URL + "/v1/users/roles",
            headers: {
                "Authorization": "Bearer " + cookies.load("token")
            },
            success: function (result) {
                callback(result);
            },
            error: function (e) {
                Utils.displayMessage('error', 'Error loading user roles').then(r => r);
            }
        })
    };

    /*
    * Method to change the status of a user - block/unblock
    * Parameters:
    * + data: The data of the new user to be edited
    * + method: The method to be called on the server code
    * + callback: The method to handle the response coming from the server
    * */
    static changeStatus = (data, method, callback) => {
        const id = data.id;
        $.ajax({
            method: "PUT",
            url: Api.DEV_BASE_URL + "/v1/users/" + id,
            headers: {
                "Authorization": "Bearer " + cookies.load("token")
            },
            dataType: "json",
            data: {data: data, method: method},
            success: function (result) {
                callback(result);
            },
            error: function (e) {
                Utils.displayMessage('error', 'Failed', 'There was an error in your operation').then(r => r);
            }
        })
    }


}
