import App from "../App";
import SignIn from "../containers/Login/Login";
import Register from "../containers/Register/Register";
import ResetPasswordEmail from "../containers/ForgotPassword/ResetPasswordEmail";
import ResetPasswordForm from "../containers/ForgotPassword/ResetPasswordForm";


const indexRoutes = [
    { path: "/login", component: SignIn},
    { path: "/register", component: Register},
    { path: "/forgot-password", component: ResetPasswordEmail},
    { path: "/reset-password", component: ResetPasswordForm},
    { path: "/", component: App},
];

export default indexRoutes;
